import React, { useEffect, useRef, useState } from "react";
import { banner, healthPackages, images } from "../../Config/images";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Checkbox,
  Grid,
  Pagination,
  Typography,
} from "@mui/material";
import CommonBreadcrumbs from "../../componant/Breadcrumb/Breadcrumb";
import HomeBooking from "../../componant/HomeBooking/HomeBooking";
import "./HealthSreen.css";
import Booking from "../../componant/Booking/Booking";
import { bookAppointment, getHealthScreenInfo } from "../../Services/services";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify"; // Import the toast library
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const HealthCheckDetails = ({ headerData, footerData }) => {
  const location = useLocation();
  const params = useParams();
  const [heathScreenInfoData, setHealthScreenInfodata] = useState({});

  const breadcrumbsData = [
    { label: "Home", url: "/" },
    {
      label: "Health Screening",
      url: `/health-screening`,
    },
    {
      label: heathScreenInfoData && heathScreenInfoData.name,
      url: `/health-checkup/${params.id}`,
    },
  ];

  const topRef = useRef(null);


  const [bookAppoinData, setBookAppoinData] = useState({});
  const [disabled, setDisabled] = useState(true);
  const [formData, setFormData] = useState({
    name: "",
    mobile_number: "",
    email: "",
    date: "",
    message: "",
  });

  useEffect(() => {
    handleHealthCheckDetails();
  }, []);
  const bookAppointRef = useRef(null);
  useEffect(() => {
    const topElement = topRef.current;
    // Scroll to the topRef with offset for navbar height
    const navbarHeight = 800; // Replace with your actual navbar height
    const topPosition = topElement.offsetTop - navbarHeight;
console.log("tttttttttt" ,location?.state)
    // Check if there is a navigation intent
    if (location?.state?.section === "book-appointment") {
      setTimeout(() => {
          bookAppointRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 300); // Delay for 300ms to allow rendering
  } else if (location?.state?.section === "view-more") {
      // Scroll to the top of the page
      window.scrollTo({ top: topPosition, behavior: "smooth" });
    } else if (topElement) {

      window.scrollTo({
        top: topPosition,
        behavior: "smooth",
      });
    }
  }, [location?.state]);

  const handleHealthCheckDetails = async () => {
    try {
      const resp = await getHealthScreenInfo(params.id);
      if (resp.data.status == true) {
        setHealthScreenInfodata(resp.data.responseBody);
      }
    } catch (error) {
      setHealthScreenInfodata([]);
    }
  };

  const handleBookAppoinData = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    // Format the date to yyyy-mm-dd
    const formattedDate = formData.date
      ? new Date(formData.date).toISOString().split("T")[0]
      : "";
    const param = {
      type: 2,
      name: formData.name,
      mobile_number: formData.mobile_number,
      email: formData.email,
      message: formData.message,
      health_screening_id: params.id,
      date: formattedDate,
    };

    try {
      const resp = await bookAppointment(param);
      if (resp.data.status === true) {
        setBookAppoinData(resp.data.responseBody);
        toast.success("Appointment booked successfully!");
        setFormData({
          name: "",
          mobile_number: "",
          email: "",
          date: "",
          message: "",

        });
        setDisabled(true)
      }
    } catch (error) {
      setFormData({
        name: "",
        mobile_number: "",
        email: "",
        date: "",
        message: "",
      });
      setDisabled(true)
      toast.error("Failed to book an appointment. Please try again.");
      console.log("handlesubmit", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const nameRegex = /^[a-zA-Z\s]{3,}$/;
    const mobileRegex = /^[0-9]{10}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;

    const formattedDate = formData.date
      ? new Date(formData.date).toISOString().split("T")[0]
      : "";

    if (!formData.name.trim()) {
      toast.error("Enter your name.");
      return false;
    }
    if (!nameRegex.test(formData.name)) {
      toast.error(
        "Name must be at least 3 characters and only contain letters."
      );
      return false;
    }

    if (!formData.mobile_number) {
      toast.error("Enter your mobile number.");
      return false;
    }
    if (!mobileRegex.test(formData.mobile_number)) {
      toast.error("Mobile number must be exactly 10 digits.");
      return false;
    }

    if (!formData.email.trim()) {
      toast.error("Enter your email.");
      return false;
    }
    if (!emailRegex.test(formData.email)) {
      toast.error("Please enter a valid email address.");
      return false;
    }

    if (!formData.date) {
      toast.error("Enter Date.");
      return false;
    }
    if (!dateRegex.test(formattedDate)) {
      toast.error("Please enter a valid date (YYYY-MM-DD).");
      return false;
    }

    if (!formData.message.trim()) {
      toast.error("Enter your message.");
      return false;
    }
    if (formData.message.trim().length <= 10) {
      toast.error("Message must be at least 10 characters long.");
      return false;
    }

    if (disabled) {
      toast.error("Please select Terms & Condition");
      return false;
    }
    return true; // If all validations pass, return true
  };
  useEffect(() => {
    const calculateHeight = () => {
      const leftBox = document.querySelector(".package-test-box");
      const rightBox = document.querySelector(".package-details-box");

      if (leftBox && rightBox) {
        // Get the scrollHeight of both boxes
        const leftHeight = leftBox.scrollHeight;
        const rightHeight = rightBox.scrollHeight;
        // Determine the shorter height, but cap it at 800px
        const minHeight = Math.min(Math.max(leftHeight, rightHeight), 800);

        // Set the height of both boxes to the calculated height
        leftBox.style.height = `${minHeight}px`;
        rightBox.style.height = `${minHeight}px`;

        // Enable scrolling if the content exceeds the calculated height
        leftBox.style.overflowY = leftHeight > minHeight ? "auto" : "hidden";
        rightBox.style.overflowY = rightHeight > minHeight ? "auto" : "hidden";
      }
    };

    calculateHeight();

    // Recalculate on window resize
    window.addEventListener("resize", calculateHeight);

    return () => {
      window.removeEventListener("resize", calculateHeight);
    };
  }, [heathScreenInfoData]);

  const handleDateChange = (date) => {
    setFormData({ ...formData, date });
  };


  const [termsAndConditionLink, setTermsAndConditionLink] = useState("");

  useEffect(() => {
    const storedData = localStorage.getItem('customApiData');

    if (storedData) {
      try {
        const parsedData = JSON.parse(storedData); // Parse the string to an array of objects
        const termsAndCondition = parsedData.find(
          (item) => item.title === "Terms and condition"
        );

        if (termsAndCondition) {
          setTermsAndConditionLink(termsAndCondition.slug); // Set the slug if found
        }
      } catch (error) {
        console.error("Error parsing localStorage data:", error);
      }
    }
  }, []);


  return (
    <>
      <div className="header-image" ref={topRef}>
        <div className="absolute-image-container">
          <img
            src={banner.doctorListBanner}
            alt="Doctors"
            className="absolute-image"
          />
          <div className="image-text-about">
            <h1 className="text-about">Health Screening</h1>
            <p className="text-health">
              Our Team of Specialists <br />
              Delivering Excellence in Every Field
            </p>
            <div className="breadcrumb" style={{ width: "260px" }}>
              <CommonBreadcrumbs items={breadcrumbsData} separator="›" />
            </div>
          </div>
        </div>
      </div>

      <HomeBooking noMargin footerData={footerData} />
      <div className="commit-sec">
        <Box className="pt-3">
          <Typography variant="h5" className="health-check-title">
            {heathScreenInfoData ? heathScreenInfoData.name : "-"}
          </Typography>
          <div className="d-flex pt-4 pb-4 pack-type-box">
            <div>
              <img
                src={healthPackages.HealthDetails}
                alt=""
                className="health-detail-img"
              />
            </div>
            <div
              className="health-detail-box"
              style={{ background: "#00AFF0" }}
            >
              <div>
                <Typography
                  variant="p"
                  sx={{
                    display: "inline-block",
                    width: "180px",
                    fontWeight: "bold",
                  }}
                >
                  Price :
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    display: "inline-block",

                    marginBottom: 1,
                  }}
                >
                  {heathScreenInfoData ? `₹${heathScreenInfoData.price}/-` : "-"}
                </Typography>
              </div>
              <div>
                <Typography
                  variant="p"
                  sx={{
                    display: "inline-block",
                    width: "180px",
                    fontWeight: "bold",
                  }}
                >
                  Available at :
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    display: "inline-block",

                    marginBottom: 1,
                  }}
                >
                  {Array.isArray(heathScreenInfoData?.hospital?.location)
                    ? heathScreenInfoData.hospital.location.join(', ') // Handle array case
                    : heathScreenInfoData?.hospital?.location
                      ? heathScreenInfoData.hospital.location.split(',').join(', ') // Handle string case
                      : "-"}
                </Typography>
              </div>
              <div>
                <Typography
                  variant="p"
                  sx={{
                    display: "inline-block",
                    width: "180px",
                    fontWeight: "bold",
                  }}
                >
                  Package Type :{" "}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    display: "inline-block",

                    marginBottom: 1,
                  }}
                >
                  {Array.isArray(heathScreenInfoData?.package?.name)
                    ? heathScreenInfoData.package.name.join(', ') // Handle array case
                    : heathScreenInfoData?.package?.name
                      ? heathScreenInfoData.package.name.split(',').join(', ') // Handle string case
                      : "-"}
                </Typography>
              </div>
              <div>
                <button className="health-check-btn mt-3"
                  onClick={() => {
                    bookAppointRef.current?.scrollIntoView({ behavior: "smooth" });
                  }}
                >
                  Book A Health Check
                </button>
              </div>
            </div>
          </div>
        </Box>

        <Box className="pt-3">
          <Typography variant="h5" className="health-check-title-data mb-3">
            {heathScreenInfoData ? heathScreenInfoData.title : "-"}
          </Typography>
          <Typography
            variant="p"
            className="health-check-p"
            dangerouslySetInnerHTML={{
              __html: heathScreenInfoData?.short_desc,
            }}
          ></Typography>
        </Box>
        <Box className="pt-4">
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box className="package-test-box" >
                <Typography variant="h6">Package Tests</Typography>
                <Box className="pt-4 package-test">
                  {heathScreenInfoData?.package_test?.map((e, index) => {
                    const number = String(index + 1).padStart(2, "0");
                    return (
                      <div key={index} style={{ display: "flex", gap: "15px" }}>
                        <b>{number}</b>
                        <p>{e}</p>
                      </div>
                    );
                  })}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className="package-details-box">
                <Typography variant="h6">Package Details</Typography>
                <h5 className="pt-2">
                  {heathScreenInfoData?.package_detail_title}
                </h5>
                {heathScreenInfoData?.package_detail?.length > 0 ? (
                  heathScreenInfoData.package_detail.map((category, index) => (
                    <Box key={index} className="pt-2">
                      <div className="pack-detail-head">{category?.title}</div>
                      {category?.points &&
                        JSON.parse(category.points).map((item, itemIndex) => (
                          <div
                            key={itemIndex}
                            style={{ display: "flex", gap: "10px" }}
                          >
                            <img
                              src={healthPackages.rightArrow} // Provide a default image if item.image is undefined
                              alt=""
                              style={{
                                marginBottom: "10px",
                                height: "20px",
                                width: "20px",
                                marginTop: "3px",
                              }}
                            />
                            <p>{item}</p>
                          </div>
                        ))}
                    </Box>
                  ))
                ) : (
                  <p>No package details available</p>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box className="pt-5" ref={bookAppointRef}>
          <Box
            className="shadow rounded-4 border border-primary-subtle book-health-popup"
          // sx={{ height: "630px" }}
          >
            <Typography
              variant="h4"
              sx={{
                height: "80px",
                backgroundColor: "#354895",
                color: "#ffffff",
                fontWeight: 700,
                fontSize: "30px",
                lineHeight: "42.3px",
                letterSpacing: "2%",
                padding: "1rem 0 0 1.5rem ",
                borderTopLeftRadius: "12px",
                borderTopRightRadius: "12px",
                marginBottom: "1rem",
              }}
              className="contact-form-title"
            >
              Book a health check
            </Typography>
            <form>
              <div className="contact-form-div">
                <input
                  type={"text"}
                  placeholder="Enter your name*"
                  className="contact-input"
                  value={formData.name}
                  name="name"
                  onChange={handleChange}
                />
              </div>
              <div className="contact-form-div">
                <input
                  type="tel"
                  placeholder="Enter mobile number*"
                  className="contact-input"
                  value={formData.mobile_number}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*$/.test(value)) { // Allows only numeric values
                      handleChange(e);
                    }
                  }}
                  pattern="[0-9]*" // Allows only numbers
                  maxLength="10"
                  name="mobile_number"
                />
              </div>
              <div className="contact-form-div">
                <input
                  type={"text"}
                  placeholder="Enter email id*"
                  className="contact-input"
                  value={formData.email}
                  onChange={handleChange}
                  name="email"
                />
              </div>
              <div className="contact-form-div">
                {/* <input
                  type="date"
                  placeholder="Date of Appoinment"
                  className="contact-input"
                  data-date-format="DD MMMM YYYY"
                  value={formData.date}
                  onChange={handleChange}
                  name="date"
                  pattern="\d{4}-\d{2}-\d{2}"
                  style={{
                    paddingLeft: "1rem",
                    paddingRight: "1rem",
                    appearance: "textfield", // Remove spin buttons on some browsers
                  }}
                /> */}
                <DatePicker
                  selected={formData.date ? new Date(formData.date) : null} // Ensure date is parsed correctly
                  onChange={(date) => handleDateChange(date)} // Handle date change
                  placeholderText="Date of Appointment"
                  className="contact-input"
                  minDate={new Date()} // Disable dates before today
                  maxDate={new Date(new Date().setMonth(new Date().getMonth() + 2))} // Disable dates after 2 months from today
                  customInput={
                    <div
                      style={{ position: "relative" }}
                    >
                      <label
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "12px",
                          fontSize: "14px",
                          color: formData.date ? "#333" : "#bbb",
                          transform: "translateY(-50%)",
                          pointerEvents: "none",
                          transition: "all 0.2s ease-in-out",
                        }}
                      >
                        {formData.date
                          ? new Date(formData.date).toLocaleDateString("en-GB") // Format the date as "dd/MM/yyyy"
                          : "Date of Appointment"}
                      </label>
                      <i
                        class="fa-regular fa-calendar-days"
                        aria-hidden="true"
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          color: "#757575",
                          pointerEvents: "none",
                        }}
                      ></i>
                    </div>
                  }
                />


              </div>
              <div className="contact-form-div mb-2">
                <textarea
                  rows={36}
                  cols={20}
                  type={"text"}
                  placeholder="Write your message"
                  className="contact-input-textarea"
                  value={formData.message}
                  onChange={handleChange}
                  name="message"
                />
              </div>
              <div className="d-flex gap-2 contact-form-div">
                <Checkbox
                  style={{ marginTop: "-34px" }}
                  checked={!disabled}
                  onChange={() => setDisabled(!disabled)}
                  sx={{
                    "&:hover": {
                      backgroundColor: "transparent", // Remove hover background
                    },
                  }}
                />
                <p style={{ color: "#333333" }} className="book-health">
                  By submitting the form, I accept the{" "}
                  {termsAndConditionLink ? (
                    <a href={`/${termsAndConditionLink}`}>Terms & Conditions</a>
                  ) : (
                    "Terms & Conditions"
                  )}{" "}
                  of usage of the data and consent to the same.
                  I am aware that the information on this form will be sent to Global Hospital.
                </p>
              </div>
              <div className="contact-form-div ">
                <button
                  className="book-health-check  mb-4"
                  onClick={handleBookAppoinData}
                // style={{
                //   backgroundColor: disabled ? "#bdbdbd" : "",
                //   color: disabled ? "#000" : "",
                //   cursor: disabled ? "not-allowed" : "pointer",
                // }}
                >
                  Book a health check
                </button>
              </div>
            </form>
          </Box>
        </Box>
      </div>
      <div className="pt-3">
        {" "}
        <Booking headerData={headerData} footerData={footerData} />
      </div>
    </>
  );
};

export default HealthCheckDetails;
