import React, { useEffect, useRef, useState } from "react";
import { images } from "../../Config/images"; // Importing image assets (if needed)
import { specialitiesIcons, specialitiesIconsNew } from "../../Config/images"; // Importing image assets (if needed)
import "../Home/Home.css"; // Ensure the correct path to your CSS file
import {
  TextField,
  InputAdornment,
  Grid2,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import HomeBooking from "../../componant/HomeBooking/HomeBooking";

import Slider from "react-slick"; // Assuming you're using react-slick for the slider
import Booking from "../../componant/Booking/Booking";
import { Link, useNavigate } from "react-router-dom";
import { getslider, getSpecialties, searchAPI } from "../../Services/services";

const Home = ({ headerData, footerData, specialties }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [sliderData, setSliderData] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [debouncedSearchText, setDebouncedSearchText] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();

  const topRef = useRef(null);

  useEffect(() => {
    const updateMobileView = () => {
      setIsMobile(window.innerWidth <= 1400); // Adjust breakpoint as needed
    };

    updateMobileView(); // Initial check
    window.addEventListener("resize", updateMobileView); // Listen for resize events

    return () => window.removeEventListener("resize", updateMobileView);
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    beforeChange: (oldIndex, newIndex) => {
      setCurrentSlide(newIndex); // Update currentSlide when the slide changes
    },
    appendDots: (dots) => (
      <div className="custom-dots">
        <ul>{dots}</ul>
      </div>
    ),
    customPaging: () => <div className="dot" />,
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await getslider();
      if (response.status === 200) {
        setSliderData(response.data.responseBody);
      }


    } catch (error) {
      console.log("Error in API call:", error);
    }
  };

  // Debounce Logic
  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      setDebouncedSearchText(searchText);
    }, 500); // 500ms debounce delay

    return () => clearTimeout(delayDebounce); // Clear timeout on component unmount or searchText change
  }, [searchText]);

  // API Call for Search
  useEffect(() => {
    const fetchSearchResults = async () => {
      if (debouncedSearchText.trim() !== "") {
        try {
          const response = await searchAPI(debouncedSearchText); // Call the API
          if (response.status === 200) {
            setSearchResults(response.data.responseBody); // Update search results
            setShowDropdown(true); // Show dropdown
          }
        } catch (error) {
          console.log("Error fetching search results:", error);
        }
      } else {
        setSearchResults([]); // Clear search results if input is empty
        setShowDropdown(false); // Hide dropdown
      }
    };

    fetchSearchResults();
  }, [debouncedSearchText]);

  const clearSearch = () => {
    setSearchText("");
    setSearchResults([]);
    setShowDropdown(false);
  };
  const displayedSpecialties = specialties.slice(0, 12);

  useEffect(() => {
    const topElement = topRef.current;
  
    if (topElement) {
      const navbarHeight = 800; // Replace with your navbar's height in pixels
      const topPosition = topElement.offsetTop - navbarHeight;
  
      window.scrollTo({
        top: topPosition,
        behavior: "smooth",
      });
    }
  }, []);
  return (
    <>
      <div className="home-booking-container-fluid" ref={topRef}>
        <div className="home-booking-container">
          <div className="slider-container">
            <Slider {...settings}>
              {sliderData.map((item, index) => (
                <div key={index}>
                  <img
                    src={item.image}
                    alt={`Slider${index}`}
                    className="slider-image"
                  />
                </div>
              ))}
            </Slider>
            {sliderData[currentSlide] && (
              <div className="slider-text">
                {/* <h1
                  className="slider-heading mt-5 pt-2"
                  style={{ width: "60%" }}
                >
                  {sliderData[currentSlide].title}
                </h1>
                <p className="slider-p mt-4" style={{ width: "60%" }}>
                  {sliderData[currentSlide].sub_title}
                </p> */}
                <div className="search-field-11 mt-3" style={{ position: "relative" }}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)} // Update search text
                    placeholder="Search for Doctors, Specialties"
                    // className="search-field-11 mt-3 mb-4"
                    InputProps={{
                      startAdornment: null, // Remove the startAdornment if you had one
                      // Add endAdornment for the icon to appear at the end
                      endAdornment: (
                        <InputAdornment position="end">
                          {searchText ? (
                            <CloseIcon onClick={clearSearch} style={{ cursor: "pointer" }} className="close-icon" />
                          ) : <SearchIcon className="searchIcon"/>}

                        </InputAdornment>
                      ),
                    }}
                  />
                  {showDropdown && searchResults.length > 0 && (
                    <Box className={`slider-dropdown ${!searchResults.some(
                      (resultGroup) =>
                        (resultGroup.type === "doctor" && resultGroup.doctors?.length > 0) ||
                        (resultGroup.type === "specialties" && resultGroup.specialties?.length > 0)
                    ) ? 'no-data' : ''}`}>
                      {searchResults.some((resultGroup) => resultGroup.type === "doctor" && resultGroup.doctors?.length > 0) ||
                        searchResults.some((resultGroup) => resultGroup.type === "specialties" && resultGroup.specialties?.length > 0) ?
                        (
                          searchResults.map((resultGroup, groupIndex) => {
                            const dataType = resultGroup.type;
                            const dataItems = dataType === "doctor" ? resultGroup.doctors : resultGroup.specialties;

                            return (
                              <div key={groupIndex} className="slider-dropdown-group">
                                <div className="slider-dropdown-header">
                                  {dataType.charAt(0).toUpperCase() + dataType.slice(1)}
                                </div>
                                {dataItems.map((item, itemIndex) => (
                                  <div
                                    key={itemIndex}
                                    className="slider-dropdown-item"
                                    onClick={() => {
                                      if (dataType === "doctor") {
                                        navigate(`/view-profile/${item.id}`);
                                      } else if (dataType === "specialties") {
                                        navigate(`/health-specialist/${item.id}`);
                                      }
                                    }}
                                  >
                                    <img
                                      src={item.image || "default-avatar.png"}
                                      alt={item.name}
                                      className="result-image"
                                    />
                                    <span>{item.name}</span>
                                  </div>
                                ))}
                              </div>
                            );
                          })
                        ) : (
                          <div className="no-results-found">
                            <p className="no-results-found-p">No results found</p>
                          </div>
                        )}
                    </Box>
                  )}



                </div>
                {/* {showDropdown && searchResults.length > 0 && (
                  <Box className="dropdown">
                    {searchResults.map((resultGroup, groupIndex) => (
                      <div key={groupIndex} className="dropdown-group">
                        <div className="dropdown-header">{resultGroup.type}</div>
                        {resultGroup[resultGroup.type]?.map((item, itemIndex) => (
                          <div
                            key={itemIndex}
                            className="dropdown-item"
                            onClick={() => {
                              if (resultGroup.type === "doctor") {
                                navigate(`/doctor/${item.id}`); // Redirect to doctor profile
                              } else if (resultGroup.type === "specialties") {
                                navigate(`/specialty/${item.id}`); // Redirect to specialty page
                              }
                            }}
                          >
                            <img
                              src={item.image || "default-avatar.png"} // Fallback image
                              alt={item.name}
                              className="result-image"
                            />
                            <span>{item.name}</span>
                          </div>
                        ))}
                      </div>
                    ))}
                  </Box>
                )} */}

              </div>
            )}
          </div>
        </div>
      </div>
      <div>
        <HomeBooking footerData={footerData} />
      </div>

      <div className="container-fluid specialties-section mb-5">
        {/* Header Section */}
        <div className="mt-5 mb-3 key-spec-sec">
          <h2 className="specialties-title">Key Specialties</h2>
          <div className="healthcare">
            <p className="personalized-text">
              Personalized Healthcare Solutions <br />
              Designed Just For You
            </p>
            <button variant="outlined" className="view-all-btn">
              <a href="/specialties">View All</a>
            </button>
          </div>
        </div>

        {/* Grid Section */}
        <div className="mt-4">
          <Grid container spacing={2} justifyContent="center">
            {specialties.map((specialty, index) => (
              <Grid item xs={6} sm={4} md={3} lg={2.4} key={index}>
                <Box
                  className="specialties-box"
                  onClick={() => navigate(`/health-specialist/${specialty.id}`)}
                  style={{cursor:"pointer"}}
                >
                  <img
                    src={specialty.image}
                    alt={specialty.name}
                    className="special-image"
                  />
                  <Typography variant="h6" className="specialty-heading"
                    sx={{
                      display: "-webkit-box",
                      WebkitLineClamp: 2, // Limit to 2 lines
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxHeight: "200px",
                    }}
                  >
                    {specialty.title}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>

        </div>
      </div>

      <div class="container-fluid key-facts-container mb-5">
        <Grid container spacing={4}>
          <Grid item lg={7} md={12}>
            <h3>KEY FACTS</h3>

            <h1 className="mt-3">
              Essential Insights At A Glance
              <br />
              Explore Our Key Facts.
            </h1>
            <Grid container spacing={2} className="mt-5">
              <Grid item lg={4} md={4} xs={6}>
                <div className="fact-box">
                  <img src={images.medicalstaff} alt="Medical Staff Icon" />
                  <h2>50+</h2>
                  <p>Medical staff</p>
                </div>
              </Grid>
              <Grid item lg={4} md={4} xs={6}>
                <div className="fact-box">
                  <img src={images.icubed} alt="Beds Icon" />
                  <h2>75+</h2>
                  <p>Beds</p>
                </div>
              </Grid>
              <Grid item lg={4} md={4} xs={6}>
                <div className="fact-box">
                  <img src={images.hospitalbed} alt="ICU Bed Icon" />
                  <h2>25+</h2>
                  <p>Bed ICU</p>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={5} md={12} className="home-keyfact-img">
            {" "}
            <img
              src={images.keyreactgle}
              alt="Tablet View"
              class="img-fluid key-fact-img"
            />
          </Grid>
        </Grid>

        {/* <div class="row justify-content-center">
          <div class="col-md-5  key-facts-content  text-md-left">
            <h3 class="mx-5 px-5">KEY FACTS</h3>
            <h1 className="mx-5 px-5">
              Essential Insights At A Glance
              <br />
              Explore Our Key Facts.
            </h1>

            <div class="row facts-grid mt-5 pt-2 ">
              <div class="col-12 col-sm-6 col-md-4 fact-box">
                <img src={images.medicalstaff} alt="Medical Staff Icon" />
                <h2>50+</h2>
                <p>Medical staff</p>
              </div>
              <div class="col-12 col-sm-6 col-md-4 fact-box">
                <img src={images.icubed} alt="Beds Icon" />
                <h2>75+</h2>
                <p>Beds</p>
              </div>
              <div class="col-12 col-sm-6 col-md-4 fact-box">
                <img src={images.hospitalbed} alt="ICU Bed Icon" />
                <h2>25+</h2>
                <p>Bed ICU</p>
              </div>
            </div>
          </div>
          <div class="col-md-6 key-facts-image text-center">
            <img src={images.keyreactgle} alt="Tablet View" class="img-fluid" />
          </div>
        </div> */}
      </div>

      <div>
        <Booking headerData={headerData} footerData={footerData} />
      </div>
    </>
  );
};

export default Home;