import React, { useState, useEffect } from "react";
import {
    Dialog,
    Checkbox,
    Typography,
    Box,
    TextField,
    MenuItem,
    Button,
    FormControl,
    InputLabel,
} from "@mui/material";
import { bookAppointment } from "../../Services/services";
import { toast } from "react-toastify"; // Import the toast library
import "react-toastify/dist/ReactToastify.css";
import { getDiseases } from "../../Services/services";
import CloseIcon from "@mui/icons-material/Close";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import "../AppointmentPopUp/AppointmentPopUp.css"

const AppointmentPopUp = ({ open, onClose, doctor_id }) => {

    const [disabled, setDisabled] = useState(true);
    const [diseases, setDiseases] = useState([]);
    const [diseaseId, setDiseaseId] = useState("");
    const [enteredValue, setEnteredValue] = useState(""); // Track user input
    const [formData, setFormData] = useState({
        name: "",
        mobile_number: "",
        email: "",
        disease: "",
        message: "",
        date: ""
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const getDiseasesList = async () => {
        try {
            const resp = await getDiseases();
            if (resp.data.status === true) {
                setDiseases(resp.data.responseBody)
            }
        } catch (error) {
            console.log("Error", error);
        }
    }
    useEffect(() => {
        getDiseasesList();
    }, [])
    const validateForm = () => {
        const nameRegex = /^[a-zA-Z\s]{3,}$/;
        const mobileRegex = /^[0-9]{10}$/;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!formData.name) {
            toast.error("Enter your name.");
            return false;
        }
        if (!nameRegex.test(formData.name)) {
            toast.error(
                "Name must be at least 3 characters and only contain letters."
            );
            return false;
        }

        if (!formData.mobile_number) {
            toast.error("Enter your mobile number.");
            return false;
        }
        if (!mobileRegex.test(formData.mobile_number)) {
            toast.error("Mobile number must be exactly 10 digits.");
            return false;
        }

        if (!formData.email.trim()) {
            toast.error("Enter your email.");
            return false;
        }
        if (!emailRegex.test(formData.email)) {
            toast.error("Please enter a valid email address.");
            return false;
        }

        if (!formData.message) {
            toast.error("Enter your message.");
            return false;
        }
        if (formData.message.trim().length <= 10) {
            toast.error("Message must be at least 10 characters long.");
            return false;
        }

        if (!diseaseId && !enteredValue) {
            toast.error("Please select a disease.");
            return false;
        }

        if (disabled) {
            toast.error("Please select Terms & Condition");
            return false;
        }
        return true; // If all validations pass, return true
    };
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }


        // Format the date to yyyy-mm-dd
        const formattedDate = formData.date
            ? new Date(formData.date).toISOString().split("T")[0]
            : "";

        const param = {
            type: 1,
            name: formData.name,
            mobile_number: formData.mobile_number,
            email: formData.email,
            message: formData.message,
            diseases_id: enteredValue || diseaseId.value,
            date: formattedDate,
            doctor_id: doctor_id
        };
        try {
            const resp = await bookAppointment(param);
            if (resp.data.status === true) {
                toast.success("Appointment booked successfully!");
                setFormData({
                    name: "",
                    mobile_number: "",
                    email: "",
                    disease: "",
                    message: "",
                });
                setDiseaseId(""); // Clear the disease ID
                setDisabled(true); // Reset the checkbox
            }

            onClose();
        } catch (error) {
            setFormData({});
            toast.error("Failed to book an appointment. Please try again.");
            console.log("handlesubmit", error);
        }
    };
    // Updated onClose function to reset the diseaseId
    const handleDialogClose = () => {
        setDiseaseId(""); // Clear disease ID
        setFormData({
            name: "",
            mobile_number: "",
            email: "",
            disease: "",
            message: "",
        }); // Reset form data
        setDisabled(true);
        setEnteredValue("");
        setSelectedDisease("");
        onClose(); // Call the passed onClose prop

    };

    const handleDateChange = (date) => {
        setFormData({ ...formData, date });
    };

    const [diseaseOptions, setDiseaseOptions] = useState([]);
    const [selectedDisease, setSelectedDisease] = useState(null);

    // Format diseases for React-Select
    useEffect(() => {
        const formattedOptions = diseases.map((disease) => ({
            value: disease.id,
            label: disease.name,
        }));
        setDiseaseOptions(formattedOptions);
    }, [diseases]);

    const [termsAndConditionLink, setTermsAndConditionLink] = useState("");

    useEffect(() => {
        const storedData = localStorage.getItem('customApiData');

        if (storedData) {
            try {
                const parsedData = JSON.parse(storedData); // Parse the string to an array of objects
                const termsAndCondition = parsedData.find(
                    (item) => item.title === "Terms and condition"
                );

                if (termsAndCondition) {
                    setTermsAndConditionLink(termsAndCondition.slug); // Set the slug if found
                }
            } catch (error) {
                console.error("Error parsing localStorage data:", error);
            }
        }
    }, []);
    
    return (
        <>
            <div className="rounded-4">
                <Dialog open={open} onClose={(event, reason) => {
                    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
                        handleDialogClose();
                    }
                }}
                    disableEscapeKeyDown
                    maxWidth={false}
                    PaperProps={{
                        style: {
                            width: "auto", // Allow the width to adjust dynamically
                            maxWidth: "90%", // Add a max limit to prevent overflow
                            borderRadius: 12,
                        },
                    }}
                    className="rounded-4"
                >
                    <Box className="shadow rounded-4 border-primary-subtle book-health-popup">
                        <Typography
                            variant="h4"
                            sx={{
                                height: "80px",
                                backgroundColor: "#354895",
                                color: "#ffffff",
                                lineHeight: "42.3px",
                                letterSpacing: "2%",
                                padding: "0 0 0 1.5rem ",
                                borderTopLeftRadius: "12px",
                                borderTopRightRadius: "12px",
                                marginBottom: "1rem",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center"
                            }}
                            className="contact-form-title"
                        >
                            <span style={{ flex: 1 }}>Book an Appointment</span> {/* This pushes the icon to the right */}
                            <CloseIcon
                                onClick={() => handleDialogClose()}
                                style={{ cursor: "pointer", marginRight: "2rem" }} // Add space to the left of the icon
                            />
                        </Typography>

                        <form>
                            <div className="contact-form-div">
                                <input
                                    type={"text"}
                                    placeholder="Enter your name*"
                                    className="appointmentpopup-contact-input"
                                    value={formData.name}
                                    name="name"
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="contact-form-div">
                                <input
                                    type="tel"
                                    placeholder="Enter mobile number*"
                                    className="appointmentpopup-contact-input"
                                    value={formData.mobile_number}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (/^\d*$/.test(value)) { // Allows only numeric values
                                            handleChange(e);
                                        }
                                    }}
                                    maxLength="10" // Restricts to 10 digits
                                    name="mobile_number"
                                    pattern="[0-9]*" // Allows only numbers
                                // maxLength="10" // Restricts to 10 digits
                                />
                            </div>
                            <div className="contact-form-div">
                                <input
                                    type={"text"}
                                    placeholder="Enter email id*"
                                    className="appointmentpopup-contact-input"
                                    value={formData.email}
                                    onChange={handleChange}
                                    name="email"
                                />
                            </div>
                            <div className="contact-form-div">
                                <Select
                                    options={diseaseOptions}
                                    placeholder="Select disease*"
                                    value={selectedDisease}
                                    onChange={(option) => {
                                        setSelectedDisease(option);
                                        setDiseaseId(option)
                                    }}
                                    onInputChange={(inputValue, { action }) => {
                                        if (action === "input-change") {
                                            console.log("sjdbjkhncjsdm", inputValue)
                                            setEnteredValue(inputValue); // Track the current input value
                                        }
                                    }}
                                    inputValue={enteredValue} // Bind the input value
                                    isSearchable
                                    isClearable
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                />
                            </div>
                            <div className="contact-form-div">
                                <DatePicker
                                    selected={formData.date ? new Date(formData.date) : null} // Ensure date is parsed correctly
                                    onChange={(date) => handleDateChange(date)} // Handle date change
                                    placeholderText="Date of Appointment"
                                    className="appointmentpopup-contact-input"
                                    minDate={new Date()} // Disable dates before today
                                    maxDate={new Date(new Date().setMonth(new Date().getMonth() + 2))} // Disable dates after 2 months from today
                                    customInput={
                                        <div
                                            style={{ position: "relative" }}
                                        >
                                            <label
                                                style={{
                                                    position: "absolute",
                                                    top: "50%",
                                                    left: "12px",
                                                    fontSize: "14px",
                                                    color: formData.date ? "#333" : "#bbb",
                                                    transform: "translateY(-50%)",
                                                    pointerEvents: "none",
                                                    transition: "all 0.2s ease-in-out",
                                                }}
                                            >
                                                {formData.date
                                                    ? new Date(formData.date).toLocaleDateString("en-GB") // Format the date as "dd/MM/yyyy"
                                                    : "Date of Appointment"}
                                            </label>
                                            <i
                                                class="fa-regular fa-calendar-days"
                                                aria-hidden="true"
                                                style={{
                                                    position: "absolute",
                                                    right: "10px",
                                                    top: "50%",
                                                    transform: "translateY(-50%)",
                                                    color: "#757575",
                                                    pointerEvents: "none",
                                                }}
                                            ></i>
                                        </div>
                                    }
                                />


                            </div>
                            <div className="contact-form-div mb-2">
                                <textarea
                                    rows={36}
                                    cols={20}
                                    type={"text"}
                                    placeholder="Write your message"
                                    className="appointmentpopup-contact-input-textarea"
                                    value={formData.message}
                                    onChange={handleChange}
                                    name="message"
                                />
                            </div>
                            <div className="d-flex gap-2 contact-form-div">
                                <Checkbox
                                    checked={!disabled}
                                    onChange={() => setDisabled(!disabled)}
                                    sx={{
                                        "&:hover": {
                                            // Remove hover background
                                        },
                                    }}
                                />
                                <p style={{ color: "#333333" }} className="book-health">
                                    By submitting the form, I accept the{" "}
                                    {termsAndConditionLink ? (
                                        <a href={`/${termsAndConditionLink}`}>Terms & Conditions</a>
                                    ) : (
                                        "Terms & Conditions"
                                    )}{" "}
                                    of usage of the data and consent to the same.
                                    I am aware that the information on this form will be sent to Global Hospital.
                                </p>
                            </div>
                            <div className="contact-form-div ">
                                <button
                                    className="book-health-check  mb-4"
                                    onClick={handleSubmit}
                                // style={{
                                //     backgroundColor: disabled ? "#bdbdbd" : "",
                                //     color: disabled ? "#000" : "",
                                //     cursor: disabled ? "not-allowed" : "pointer",
                                // }}
                                >
                                    Book Now!
                                </button>
                            </div>
                        </form>



                    </Box>



                </Dialog>
            </div>
        </>
    );
};

export default AppointmentPopUp;
