import React, { useEffect, useRef, useState } from "react";
import { banner, images } from "../../Config/images";
import HomeBooking from "../../componant/HomeBooking/HomeBooking";
import CommonBreadcrumbs from "../../componant/Breadcrumb/Breadcrumb";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Stack,
} from "@mui/material";
import SearchBar from "material-ui-search-bar";
import HealthCheckCard from "../../componant/HealthPackages/HealthPackages";
import { styled } from "@mui/material/styles";
import {
  getHealthScreen,
  getHealthScreenPackage,
  getSpecialistList
} from "../../Services/services";

const HealthScreen = ({ headerData, footerData }) => {
  const breadcrumbsData = [
    { label: "Home", url: "/" },
    {
      label: "Health Screening",
      url: "/health-screening",
    },
  ];

  const topRef = useRef(null);

  const [searchTerm, setSearchTerm] = useState("");
  const [healthScreenData, setHealthScreenData] = useState([]);
  const [healthPackageData, setHealthPackageData] = useState([]);
  const [specialties, setSpecialtiesList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10); // Total number of items
  const [totalPages, setTotalPages] = useState(1);
  const [selectedHospitalId, setSelectedHospitalId] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
  const [specId, setSpecId] = useState("")

  const handleSearchChange = (event) => {
    console.log(event);
    if (event && event.target) {
      setSearchTerm(event.target.value);
    }
  };
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [searchTerm]);

  const CustomPagination = styled(Pagination)(({ theme }) => ({
    "& .MuiPaginationItem-root": {
      borderColor: "var(--secondary-color)",
      borderWidth: "1px",
      borderStyle: "solid",
      marginTop: "30px",
    },
    "& .Mui-selected": {
      borderColor: "var(--secondary-color)",
      backgroundColor: "var(--secondary-color)  !important",
      color: "var(--white)",
    },
  }));

  useEffect(() => {
    handleHealthScreenData(currentPage);
  }, [currentPage, selectedHospitalId, specId, debouncedSearchTerm]);

  useEffect(() => {
    healthScreenPackage();
    getSpecialists();
  }, []);

  const handleHealthScreenData = async (page) => {
    const params = {
      per_page: perPage,
      page: page,
      searchTerm: debouncedSearchTerm,
      hospitals_id: selectedHospitalId !== "all" ? selectedHospitalId : '',
      specialties_id: specId !== "all" ? specId : ''
    };

    try {
      const resp = await getHealthScreen(params);
      if (resp.data.status == true) {
        setHealthScreenData(resp.data.responseBody);
        setTotalPages(Math.ceil((resp.data.responseBody.total || 0) / perPage));
      }
    } catch (error) {
      setHealthScreenData([]);
      console.log("handleSubmit", error);
    }
  };

  const healthScreenPackage = async () => {
    try {
      const resp = await getHealthScreenPackage();
      if (resp.data.status == true) {
        setHealthPackageData(resp.data.responseBody);
      }
    } catch (error) {
      setHealthPackageData([]);
    }
  };

  const getSpecialists = async () => {
    try {
      const response = await getSpecialistList();
      if (response.data.status === true) {
        setSpecialtiesList(response.data.responseBody);
      }
    } catch (error) {
      console.log("error retrieving hospital list", error);
    }
  };
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleChange = (event) => {
    setSelectedHospitalId(event.target.value);
  };
  const handleSpecialitiesChange = (event) => {
    setSpecId(event.target.value);
  };

 useEffect(() => {
    const topElement = topRef.current;
  
    if (topElement) {
      const navbarHeight = 800; // Replace with your navbar's height in pixels
      const topPosition = topElement.offsetTop - navbarHeight;
  
      window.scrollTo({
        top: topPosition,
        behavior: "smooth",
      });
    }
  }, []);

  return (
    <>
      <div className="header-image" ref={topRef}>
        {/* Absolute Positioned Image */}
        <div className="absolute-image-container">
          <img
            src={banner.healthScreenBanner} // Replace with your image source
            alt="Doctors"
            className="absolute-image"
          />
          <div className="image-text-about">
            <h1 className="text-about">Health Screening</h1>
            <p className="text-health">
              Our Team of Specialists <br />
              Delivering Excellence in Every Field
            </p>
            <div className="breadcrumb" style={{ width: "260px" }}>
              <CommonBreadcrumbs items={breadcrumbsData} separator="›" />
            </div>
          </div>
        </div>
      </div>

      <HomeBooking noMargin footerData={footerData} />

      <div className="commit-sec">
        <div className="doctor-title mt-3">Health Screening & Packages</div>
        <Grid container spacing={2} className="mt-4">
          <Grid item xs={12} md={6}>
            {" "}
            <SearchBar
              value={searchTerm}
              onChange={(newValue) => setSearchTerm(newValue)} // Handle user typing
              onRequestSearch={() => handleSearchChange()}
              onCancelSearch={() => setSearchTerm("")}
              placeholder="Search for health screening & packages"
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{ textAlign: "end" }}
            className="filter-drop"
          >
            <FormControl
            style={{
              width: "200px",
              marginRight: "20px",
             
            }}
              className="filterSpecialist"
            >
              <InputLabel id="demo-simple-select-label filter-drop-data">
                Filer By Packages
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                className="filter-drop-data-1"
                value={selectedHospitalId}
                label="Filter By Packages"
                onChange={handleChange}
                style={{ textAlign: "left" }}
              >
                <MenuItem value="all">All</MenuItem>
                {healthPackageData.map((e) => (
                  <MenuItem key={e.id} value={e.id}>
                    {e.name}
                  </MenuItem>
                ))}

              </Select>
            </FormControl>
            <FormControl
              style={{ width: "200px" }}
              className="filterSpecialist"
            >
              <InputLabel id="demo-simple-select-label filter-drop-data">
                Filter By Specialty
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                 className="filter-drop-data-1"
                label="Filter By Specialist"
                value={specId}
                onChange={handleSpecialitiesChange}
                style={{ textAlign: "left" }}
              >
                <MenuItem value="all">All</MenuItem>
                {specialties.map((e) => {
                  return (
                    <MenuItem value={e.id}>{e.title}</MenuItem>
                  )
                })}


              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <div className="pt-4">
          <HealthCheckCard healthScreenData={healthScreenData} />
        </div>
        {healthScreenData?.data?.length > 0 && (
          <Stack
            spacing={2}
            justifyContent="center"
            alignItems="center"
            className="mt-3 mb-3 d-flex pagination-stack"
            sx={{
              padding: "16px",
              borderRadius: "8px",
            }}
          >
            <CustomPagination
              count={totalPages} // Total number of pages
              page={currentPage} // Current page
              onChange={handlePageChange} // Page change handler
              sx={{
                "& .MuiPaginationItem-ellipsis": {
                  color: "#01ADEC",
                },
                "& .MuiPaginationItem-root.Mui-selected": {
                  backgroundColor: "#01ADEC",
                  color: "#fff",
                },
              }}
            />
          </Stack>
        )
        }



      </div>
    </>
  );
};

export default HealthScreen;
