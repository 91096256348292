import React, { useState, useEffect, useRef, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  banner,
  healthPackages,
  images,
  whyChooseUs,
} from "../../Config/images";
import CommonBreadcrumbs from "../../componant/Breadcrumb/Breadcrumb";
import HomeBooking from "../../componant/HomeBooking/HomeBooking";
import {
  Box,
  Grid,
  Typography,
  Card,
  CardMedia,
  CardContent,
} from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Booking from "../../componant/Booking/Booking";
import { getSpecialtyDetails } from "../../Services/services";

const SpecialistHistory = ({ doctors, headerData, footerData }) => {
  const params = useParams();
  const navigate = useNavigate();
  // Create a ref for the main container
  const topRef = useRef(null);
  const departmentRef = useRef(null);
  const ourDoctorsRef = useRef(null);
  const diagnosisTreatmentRef = useRef(null);
  const whyChooseUsRef = useRef(null);
  const [specialistDetails, setSpecialistDetails] = useState([]);
  const [diseaseId, setDiseaseId] = useState();
  const breadcrumbsData = [
    { label: "Home", url: "/" },
    {
      label: "Specialties",
      url: "/specialties",
    },
    {
      label: specialistDetails?.title,
      url: `/health-specialist/${params.id}`,
    },
  ];
  const CustomDoctorArrow = ({ onClick, direction }) => {
    const styles = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      // backgroundColor: "#c0c0c0", // grey background
      // color: "#4A60E0", // Blue arrow color
      borderRadius: "6px", // Slightly rounded corners for the square
      height: "40px", // Square dimensions
      width: "40px", // Square dimensions
      cursor: "pointer",
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      zIndex: 2,
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)", // Add shadow for the 3D effect
      ...(direction === "left" ? { left: "-40px" } : { right: "-25px" }), // Positioning
    };

    return (
      <div style={styles} onClick={onClick}>
        {direction === "left" ? <img src={images.leftIconDoctor}/>: <img src={images.rightIconDoctor}/>}
      </div>
    );
  };
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true, // Enable auto-scrolling
  autoplaySpeed: 3000,
    arrows: Boolean(specialistDetails?.header_data?.doctor_section),
    nextArrow:
    specialistDetails?.header_data?.doctor_section
      ? <CustomDoctorArrow direction="right"  />
      : null,
  prevArrow:
    specialistDetails?.header_data?.doctor_section
      ? <CustomDoctorArrow direction="left"  />
      : null,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  // const CustomArrow = ({ className, style, onClick, isDoctorSection }) => (
  //   <div
  //     className={className}
  //     style={{
  //       backgroundColor: !isDoctorSection ? "#354895" : "#c0c0c0", // Dark blue or light gray background
  //       color: "#ffffff", // White arrow color
  //       borderRadius: "8px", // Slightly rounded corners
  //       height: "36px", // Fixed height
  //       width: "36px", // Fixed width
  //       display: "flex", // Center align the arrow
  //       alignItems: "center",
  //       justifyContent: "center",
  //       boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Add shadow for better visibility
  //       cursor: "pointer", // Add pointer cursor
  //     }}
  //     onClick={onClick}
  //   >
  //     <span style={{ fontSize: "16px", fontWeight: "bold" }}>
  //       {className.includes("next") ? ">" : "<"}
  //     </span>
  //   </div>
  // );

  const CustomArrow = ({ onClick, direction, isDoctorSection }) => {
    const styles = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: !isDoctorSection ? "#354895" : "#c0c0c0", // Background color
      color: "#fff", // White arrow color
      borderRadius: "50%",
      height: "20px",
      width: "20px",
      cursor: "pointer",
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      zIndex: 2,
      ...(direction === "left" ? { left: "-25px" } : { right: "-25px" }), // Positioning
    };

    return (
      <div style={styles} onClick={onClick}>
        {direction === "left" ? "<" : ">"}
      </div>
    );
  };

  const settingsData = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: Math.min(specialistDetails?.diseases_section?.length || 6, 6),
    slidesToScroll: 1,
    // arrows: specialistDetails?.diseases_section.length >= 6 ? true : false,
    // arrows: true,
    // nextArrow: (
    //   <CustomArrow isDoctorSection={specialistDetails?.header_data?.doctor_section} />
    // ),
    // prevArrow: (
    //   <CustomArrow isDoctorSection={specialistDetails?.header_data?.doctor_section} />
    // ),
    arrows: Boolean(specialistDetails?.diseases_section), // Enable arrows only if diseases_section is truthy
    nextArrow:
      specialistDetails?.header_data?.diseases_section
        ? <CustomArrow direction="right" isDoctorSection={specialistDetails?.header_data?.doctor_section} />
        : null,
    prevArrow:
      specialistDetails?.header_data?.diseases_section
        ? <CustomArrow direction="left" isDoctorSection={specialistDetails?.header_data?.doctor_section} />
        : null,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: Math.min(
            specialistDetails?.diseases_section?.length || 1,
            1
          ), // Show up to 1 slide for small screens
          arrows: true, // Disable arrows for small screens
        },
      },
      {
        breakpoint: 578,
        settings: {
          slidesToShow: Math.min(
            specialistDetails?.diseases_section?.length || 2,
            2
          ), // Show up to 2 slide for medium screens
          arrows: true, // Disable arrows for medium screens
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: Math.min(
            specialistDetails?.diseases_section?.length || 3,
            3
          ), // Show up to 3 slide for medium screens
          arrows: true, // Disable arrows for medium screens
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: Math.min(
            specialistDetails?.diseases_section?.length || 4,
            4
          ), // Show up to 4 slide for medium screens
          arrows: true, // Disable arrows for medium screens
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: Math.min(
            specialistDetails?.diseases_section?.length || 6,
            6
          ), // Show up to 6 slides for larger screens
          arrows: true,
        },
      },
    ],
  };

  const getspecialistdetails = async () => {
    try {
      const response = await getSpecialtyDetails(params.id);
      if (response.data.status === true) {
        setSpecialistDetails(response.data.responseBody);
        setDiseaseId(response.data.responseBody.diseases_section?.[0]?.id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getspecialistdetails();
  }, []);

  // Get the currently selected disease data
  const selectedDisease = specialistDetails?.diseases_section?.find(
    (disease) => disease.id === diseaseId
  );

  // Extract left and right data from details_arr
  const leftData = selectedDisease?.details_arr?.[0]?.left;
  const rightData = selectedDisease?.details_arr?.[0]?.right;

  const [maxHeight, setMaxHeight] = useState(0); // Initial height of 800px
  const [maxHeight1, setMaxHeight1] = useState(0);

  const [activeSection, setActiveSection] = useState("");

  useEffect(() => {
    // Calculate the maximum height among leftData and rightData containers
    const calculateMaxHeight = () => {
      const leftBoxes = document.querySelectorAll(".package-test-box");
      const rightBoxes = document.querySelectorAll(".package-details-box");

      let maxBoxHeight; // Default height

      // Loop through each leftBox and rightBox to find the maximum scrollHeight
      leftBoxes.forEach((box) => {
        maxBoxHeight = Math.max(0, box.scrollHeight);
      });

      rightBoxes.forEach((box) => {
        maxBoxHeight = Math.max(0, box.scrollHeight);
      });

      // Ensure maxBoxHeight doesn't exceed 800
      maxBoxHeight = Math.max(maxBoxHeight, 0);

      setMaxHeight(maxBoxHeight); // Update the state
    };

    calculateMaxHeight();

    // Recalculate on window resize
    window.addEventListener("resize", calculateMaxHeight);

    return () => {
      window.removeEventListener("resize", calculateMaxHeight);
    };
  }, [specialistDetails]); // Recalculate when leftData or rightData changes

  useEffect(() => {
    // Calculate the maximum height among leftData and rightData containers
    const calculateMaxHeight = () => {
      const leftBoxes = document.querySelectorAll(".ent-treatment-box");
      const rightBoxes = document.querySelectorAll(".ent-Procedures-box");
      let maxBoxHeight;
      // Log the heights of individual boxes for debugging
      leftBoxes.forEach((box) => {
        console.log("Left box scrollHeight:", box.scrollHeight);
        maxBoxHeight = Math.max(0, box.scrollHeight);
      });

      rightBoxes.forEach((box) => {
        console.log("Right box scrollHeight:", box.scrollHeight);
        maxBoxHeight = Math.max(0, box.scrollHeight);
      });

      // Ensure maxBoxHeight doesn't exceed 800
      maxBoxHeight = Math.max(0, maxBoxHeight);

      setMaxHeight1(maxBoxHeight); // Update the state
    };

    calculateMaxHeight();

    // Recalculate on window resize
    window.addEventListener("resize", calculateMaxHeight);

    return () => {
      window.removeEventListener("resize", calculateMaxHeight);
    };
  }, [specialistDetails]);

  useEffect(() => {
    topRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);

  // Intersection Observer for Active Section
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5, // Trigger when 50% of the section is visible
    };

    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id); // Update active section
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, options);

    // Observe each section
    const sections = [
      departmentRef.current,
      ourDoctorsRef.current,
      diagnosisTreatmentRef.current,
      whyChooseUsRef.current,
    ];
    sections.forEach((section) => {
      if (section) observer.observe(section);
    });

    return () => {
      sections.forEach((section) => {
        if (section) observer.unobserve(section);
      });
    };
  }, []);

  useEffect(() => {
     const topElement = topRef.current;
   
     if (topElement) {
       const navbarHeight = 800; // Replace with your navbar's height in pixels
       const topPosition = topElement.offsetTop - navbarHeight;
   
       window.scrollTo({
         top: topPosition,
         behavior: "smooth",
       });
     }
   }, []);

  return (
    <>
      <div className="header-image" ref={topRef}>
        <div className="absolute-image-container">
          <img
            src={banner.specilistDetailBanner} // Replace with your image source
            alt="Doctors"
            className="absolute-image"
          />
          <div className="image-text-about">
            <h1 className="text-about">{specialistDetails?.title}</h1>
            <p className="text-health specialities-title-p" style={{marginBottom: "5px"}}>
              {specialistDetails?.short_description}
            </p>
            <div className="breadcrumb" style={{marginTop: "20px"}}>
              <CommonBreadcrumbs items={breadcrumbsData} separator="›" />
            </div>
          </div>
        </div>
      </div>
      <HomeBooking noMargin footerData={footerData} />
      <div className="commit-sec">
        <Box className="pt-2 specialist-subcategory">
          {specialistDetails?.header_data?.first_section && (
            <button
              onClick={() =>
                departmentRef.current.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                })}
              style={{ color: activeSection === "department" ? "#01adec" : "", fontWeight:700 }}
            >
              {specialistDetails?.first_section?.title}
            </button>
          )}
          {specialistDetails?.header_data?.doctor_section && (
            <button
              onClick={() =>
                ourDoctorsRef.current.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                })
              }
              style={{ color: activeSection === "doctors" ? "#01adec" : "", fontWeight:700 }}
            >
              Our Doctors
            </button>
          )}
          {specialistDetails?.header_data?.diseases_section && (
            <button
              onClick={() =>
                diagnosisTreatmentRef.current.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                })
              }
              style={{ color: activeSection === "diagnosis" ? "#01adec" : "" , fontWeight:700}}
            >
              Diagnosis and Treatment of Diseases
            </button>
          )}
          {specialistDetails?.header_data?.why_choose_us_section && (
            <button
              onClick={() =>
                whyChooseUsRef.current.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                })
              }
              style={{ color: activeSection === "whyChooseUs" ? "#01adec" : "", fontWeight:700 }}
            >
              Why Choose Us?
            </button>
          )}
        </Box>

        <Box className="pt-5 department" ref={departmentRef} id="department">
          <Grid container spacing={3}>
            <Grid item xs={12} md={7} lg={7} sm={12}>
              <div className="health-spec-title">
                <h2>{specialistDetails?.first_section?.title}</h2>
                <p className="global-about mt-4">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: specialistDetails?.first_section?.description,
                    }}
                  ></p>
                </p>
              </div>
            </Grid>
            <Grid item xs={12} md={5} lg={5} sm={12}>
              <div>
                <img
                  src={specialistDetails?.first_section?.image}
                  alt=""
                  className="hospital-about-img hospital-image"
                />
              </div>
            </Grid>
          </Grid>
        </Box>

        {specialistDetails?.header_data?.second_section && (
          <>
            <Box className="pt-4 mt-5 health-spec-title">
              <h2 style={{ fontWeight: 900 }}>
                {" "}
                {specialistDetails?.second_section?.title}
              </h2>
              {/* <Typography variant="h2" className="health-spec-title" style={{ fontWeight: 700 }}>
                {specialistDetails?.second_section?.title}
              </Typography> */}
            </Box>
            <Box className="pt-4">
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} sm={12}>
                  <Box
                    className="ent-treatment-box"
                    style={{
                      height: `${maxHeight1 > 800 ? 800 : maxHeight1}px`,
                      overflowY: maxHeight1 > 800 ? "auto" : "hidden",
                    }}
                  >
                    <p
                      dangerouslySetInnerHTML={{
                        __html:
                          specialistDetails?.second_section?.left_description,
                      }}
                    ></p>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} sm={12}>
                  <Box
                    className="ent-Procedures-box"
                    style={{
                      height: `${maxHeight1 > 800 ? 800 : maxHeight1}px`,
                      overflowY: maxHeight1 > 800 ? "auto" : "hidden",
                    }}
                  >
                    <p
                      dangerouslySetInnerHTML={{
                        __html:
                          specialistDetails?.second_section?.right_description,
                      }}
                    ></p>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </>
        )}
      </div>

      {specialistDetails?.header_data?.doctor_section ? (
        <Box className="mt-4 doct-list-sec " ref={ourDoctorsRef} id="doctors">
          <Box className="commit-sec">
            <div className="d-flex justify-content-between">
              <h2 style={{ fontWeight: 700 }}>Our Doctors</h2>
              <p
                onClick={() => navigate("/doctor-list")}
                style={{ cursor: "pointer", fontWeight: 600 }}
              >
                VIEW ALL
              </p>
            </div>

            <Slider {...settings} className="pt-5">
              {specialistDetails?.doctor_section?.doctor?.map((doctor) => (
                <div
                  key={doctor.id} // Add a unique key for each doctor
                  className="d-flex gap-3 doct-details slider-doct-detail"
                  style={{ flexWrap: "wrap",cursor:"pointer" }}
                >
                  <div>
                    <img
                      src={doctor.image}
                      alt={doctor.name}
                      style={{ width: "210px" }}
                      className="doct-img"
                    />
                  </div>
                  <div>
                    <h6>{doctor.name}</h6>
                    <p className="mt-2">{doctor.type}</p>
                    <p>{doctor.specialties}</p>
                    <p>
                      Experience: <b>{doctor.experience}</b>
                    </p>
                    <p className="d-flex gap-2">
                      <img
                        style={{ width: "24px", height: "24px" }}
                        src={images.userLocationWhite}
                        alt=""
                      />{" "}
                      {doctor.hospitals}
                    </p>
                    <div
                      className="mt-3 d-flex gap-2"
                      style={{ flexWrap: "wrap" }}
                    >
                      <button
                        variant="outlined"
                        size="small"
                        className="view-profile-btn"
                        onClick={() => navigate(`/view-profile/${doctor?.id}`)} // Example navigation
                      >
                        View Profile
                      </button>
                      <button
                        variant="contained"
                        size="small"
                        className="book-appoinment-btn book-appoin-btn"
                        onClick={() =>
                          navigate(`/view-profile/${doctor?.id}`, {
                            state: { section: "book-appointment" },
                          })
                        } // Example navigation
                      >
                        Book An Appointment
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
            <div className="health-spec-title">
              <h2 style={{ paddingTop: "50px", fontWeight: 700 }}>
                {" "}
                {specialistDetails?.doctor_section?.first_description}
              </h2>
            </div>

            <Typography variant="p">
              {specialistDetails?.doctor_section?.second_description}
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box className="mt-5"></Box>
      )}

      {specialistDetails?.header_data?.diseases_section && (
        <>
          <Box
            ref={diagnosisTreatmentRef}
            className="commit-sec"
            id="diagnosis"
          >
            <Slider {...settingsData} style={{ marginTop: "-4rem" }} className="disease-slider">
              {specialistDetails?.diseases_section?.map((item, index) => (
                <Box
                  key={`${item.id}-${index}`}
                  className={`slider-box ${
                    item.id === selectedDisease?.id ? "active-slider-box" : ""
                  }`}
                  onClick={() => setDiseaseId(item.id)}
                  // style={{backgroundColor: item.id == selectedDisease?.id ? "#fffff": "#00aff0"}}
                >
                  {item.name}
                </Box>
              ))}
            </Slider>
          </Box>
          <Box className="commit-sec diagnosis-treatment">
            <Grid container spacing={2} className="pt-5">
              <Grid container spacing={3}>
                <Grid item xs={12} md={7} lg={7} sm={12}>
                  <div>
                    <h2
                      className="Experience-about-1"
                      dangerouslySetInnerHTML={{
                        __html: selectedDisease?.title,
                      }}
                    ></h2>
                    <p
                      className="global-about mt-4"
                      dangerouslySetInnerHTML={{
                        __html: selectedDisease?.description,
                      }}
                    ></p>
                  </div>
                </Grid>
                <Grid item xs={12} md={5} lg={5} sm={12}>
                  <div>
                    <img
                      src={selectedDisease?.image}
                      alt={selectedDisease?.name}
                      className="hospital-about-img"
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
            {selectedDisease?.type_of_sinusitis?.length && (
              <Box>
                <h2 className="Experience-about-1">
                  <b>There are 4 types of Sinusitis.</b>
                </h2>
                <p className="global-about mt-4">
                  {selectedDisease?.type_of_sinusitis?.map((type, index) => (
                    <div style={{ display: "flex", gap: "10px" }}>
                      <img
                        src={healthPackages.checkIcon}
                        alt=""
                        style={{
                          marginBottom: "10px",
                          height: "20px",
                          width: "20px",
                          marginTop: "3px",
                        }}
                      />
                      <p>{type}</p>
                    </div>
                  ))}
                </p>
              </Box>
            )}

            {(leftData || rightData) && (
              // Show the box only if there is either leftData or rightData
              <Box className="pt-4">
                <Grid container spacing={2}>
                  {leftData && (
                    <Grid item xs={12} md={6}>
                      <Box
                        className="package-test-box"
                        style={{
                          height: `${maxHeight > 800 ? 800 : maxHeight}px`,
                          overflowY: maxHeight > 800 ? "auto" : "hidden",
                        }}
                      >
                        {leftData.map((item, index) => (
                          <Box key={index} className="pt-4">
                            <Typography variant="h6">{item.title}</Typography>
                            <Box
                              className="pt-4 package-test"
                              dangerouslySetInnerHTML={{
                                __html: item.description,
                              }}
                            ></Box>
                            {item.points?.length > 0 && (
                              <Box className="pt-2">
                                {item.points.map((point, pointIndex) => (
                                  <div
                                    key={pointIndex}
                                    style={{ display: "flex", gap: "10px" }}
                                  >
                                    <img
                                      src={healthPackages.checkIcon}
                                      alt=""
                                      style={{
                                        marginBottom: "10px",
                                        height: "20px",
                                        width: "20px",
                                        marginTop: "3px",
                                      }}
                                    />
                                    <p>{point}</p>
                                  </div>
                                ))}
                              </Box>
                            )}
                          </Box>
                        ))}
                      </Box>
                    </Grid>
                  )}
                  {rightData && (
                    <Grid item xs={12} md={6}>
                      <Box
                        className="package-details-box"
                        style={{
                          height: `${maxHeight > 800 ? 800 : maxHeight}px`,
                          overflowY: maxHeight > 800 ? "auto" : "hidden",
                        }}
                      >
                        {rightData.map((item, index) => (
                          <Box key={index} className="pt-4">
                            <Typography variant="h6">{item.title}</Typography>
                            <Box
                              className="pt-4"
                              dangerouslySetInnerHTML={{
                                __html: item.description,
                              }}
                            ></Box>
                            {item.points?.length > 0 && (
                              <Box className="pt-2">
                                {item.points.map((point, pointIndex) => (
                                  <div
                                    key={pointIndex}
                                    style={{ display: "flex", gap: "10px" }}
                                  >
                                    <img
                                      src={healthPackages.checkIcon}
                                      alt=""
                                      style={{
                                        marginBottom: "10px",
                                        height: "20px",
                                        width: "20px",
                                        marginTop: "3px",
                                      }}
                                    />
                                    <p>{point}</p>
                                  </div>
                                ))}
                              </Box>
                            )}
                          </Box>
                        ))}
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Box>
            )}
          </Box>
        </>
      )}
      {specialistDetails?.header_data?.why_choose_us_section && (
        <Box
          className="commit-sec why-choose-us"
          ref={whyChooseUsRef}
          id="whyChooseUs"
        >
          <Typography
            variant="h5"
            className="health-check-title-data mb-3 mt-4 text-center"
          >
            Why Choose Us?
          </Typography>
          <Grid container spacing={2} className="pt-4">
            {specialistDetails?.why_choose_us.map((item, index) => (
              <Grid item md={4} sm={6} xs={12}>
                <img src={item?.image} alt="" className="whyChooseUs-img"></img>
                <Box className="pt-2">
                  <Typography variant="h6" style={{ fontWeight: 700 }}>
                    {item?.title}
                  </Typography>
                  <Typography className="pt-2">{item?.description}</Typography>
                </Box>
              </Grid>
            ))}

            {/* <Grid item md={4} sm={12}>
              <img src={whyChooseUs.image2} alt="" className="whyChooseUs-img"></img>
              <Box className="pt-2">
                <Typography variant="h6">
                  Latest Technologies
                </Typography>
                <Typography className="pt-2">
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                  Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                </Typography>
              </Box>
            </Grid>


            <Grid item md={4} sm={12}>
              <img src={whyChooseUs.image3} alt="" className="whyChooseUs-img"></img>
              <Box className="pt-2">
                <Typography variant="h6">
                  Providing Quality Care
                </Typography>
                <Typography className="pt-2">
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                  Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                </Typography>
              </Box>
            </Grid> */}
          </Grid>
        </Box>
      )}

      <div className="mt-5">
        <Booking headerData={headerData} footerData={footerData} />
      </div>
    </>
  );
};

export default SpecialistHistory;
