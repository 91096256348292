import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "../componant/Header/Header";
import Home from "../pages/Home/Home";
import AboutUs from "../pages/AbouUs/AboutUs";
import Specialties from "../pages/Specialties/Specialties";
import Contact from "../pages/Contact/Contact";
import Navbar from "../componant/Navbar/Navbar";
import Footer from "../componant/Footer/Footer";
import DoctorList from "../pages/DoctorList/DoctorList";
import ViewProfile from "../pages/ViewProfile/ViewProfile";
import { doctorImages } from "../Config/images";
import HealthScreen from "../pages/HealthScreen/HealthScreen";
import HealthCheckDetails from "../pages/HealthScreen/HealthCheckDetails";
import SpecialistHistory from "../pages/Specialties/SpecialitiesHistory";
import { getHeaderFooter, getSpecialties } from "../Services/services";
import NotFound from "../pages/404NotFound/NotFound";
import TermsAndCondition from "../componant/TermsAndCondition/TermsAndCondition";
import PrivacyPolicy from "../componant/PrivacyPolicy/PrivacyPolicy";
import CustomPage from "../componant/CustomPage/CustomPage";

const Routers = () => {
  const doctors = [
    {
      id: 1,
      name: "Dr. Jagdish B Patel",
      image: doctorImages.DrJagdishBPatel,
      title: "Consultant",
      specialty: "General & GI Surgery, MS FICS",
      experience: "30 Years",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 2,
      name: "Dr. Neel B Patel",
      image: doctorImages.DrNeelPatel,
      title: "Consultant",
      specialty: "General & GI Surgery, MS, FALS",
      experience: "06 Years",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 3,
      name: "Dr. Brijesh A Patel",
      image: doctorImages.DrBrijeshPatel,
      title: "Consultant",
      specialty: "Critical Care, DA, IDCCM",
      experience: "12 Years",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 4,
      name: "Dr. Vrooti Patel",
      image: doctorImages.ProfileImageMissing,
      title: "Consultant",
      specialty: "Critical Care, MD, IDCCM",
      experience: "N/A",
      gender: "Female",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 5,
      name: "Dr. Zinkal Patel",
      image: doctorImages.DrZinkalPatel,
      title: "Consultant",
      specialty: "Emergency Medicine, MRCEM",
      experience: "N/A",
      gender: "Female",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 6,
      name: "Dr. Khushali Patel",
      image: doctorImages.DrKhushaliPatel,
      title: "Consultant",
      specialty: "ENT, MS",
      experience: "04 Years",
      gender: "Female",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 7,
      name: "Dr. Mukul Trivedi",
      image: doctorImages.DrMukulTrivedi,
      title: "Consultant",
      specialty: "Onco Surgery, MS, MCh",
      experience: "25 Years",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 8,
      name: "Dr. Dhruv G Patel",
      image: doctorImages.DrDhruvPatel,
      title: "Consultant",
      specialty: "Onco Surgery, MS, MCh",
      experience: "06 Years",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 9,
      name: "Dr. Jayesh J Patel",
      image: doctorImages.ProfileImageMissing,
      title: "Consultant",
      specialty: "Onco Surgery, MS",
      experience: "N/A",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 10,
      name: "Dr. Roopesh Mody",
      image: doctorImages.ProfileImageMissing,
      title: "Consultant",
      specialty: "Onco Surgery, MS, MCh",
      experience: "N/A",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 11,
      name: "Dr. Mitesh Patel",
      image: doctorImages.DrMiteshPatel,
      title: "Consultant",
      specialty: "Oral & Maxillofacial Surgery, MDS",
      experience: "N/A",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 12,
      name: "Dr. Hasmukh Agrawal",
      image: doctorImages.DrHasmukhAgrawal,
      title: "Consultant",
      specialty: "Obstetrics & Gynaecology, MS",
      experience: "N/A",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 13,
      name: "Dr. Rushabh Agrawal",
      image: doctorImages.DrRushabhAgrawal,
      title: "Consultant",
      specialty: "Obstetrics & Gynaecology, MS",
      experience: "04 Years",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 14,
      name: "Dr. Meet Patel",
      image: doctorImages.DrMeetPatel,
      title: "Consultant",
      specialty: "Obstetrics & Gynaecology, MS",
      experience: "05 Years",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 15,
      name: "Dr. Varun Patel",
      image: doctorImages.DrVarunPatel,
      title: "Consultant",
      specialty: "Pulmonary Medicine, DTCD, DNB",
      experience: "14 Years",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 16,
      name: "Dr. Rushi Desai",
      image: doctorImages.DrRushiDesai,
      title: "Consultant",
      specialty: "Pulmonary Medicine, MD, DM",
      experience: "05 Years",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 17,
      name: "Dr. Shrinesh Agrawal",
      image: doctorImages.DrShrineshAgrawal,
      title: "Consultant",
      specialty: "Pulmonary Medicine, MD",
      experience: "03 Years",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 18,
      name: "Dr. Kamlesh Shah",
      image: doctorImages.DrKamleshShah,
      title: "Consultant",
      specialty: "Urology, MS, DNB",
      experience: "15 Years",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 19,
      name: "Dr. Keval Patel",
      image: doctorImages.DrKevalPatel,
      title: "Consultant",
      specialty: "Urology, MS, DNB",
      experience: "15 Years",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 20,
      name: "Dr. Anand Patel",
      image: doctorImages.DrAnandPatel,
      title: "Consultant",
      specialty: "Bariatrics Surgery, MS, DNB",
      experience: "10 Years",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 21,
      name: "Dr. Chandrika Patel",
      image: doctorImages.ProfileImageMissing,
      title: "Consultant",
      specialty: "Anaesthesia, MD",
      experience: "30 Years",
      gender: "Female",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 22,
      name: "Dr. Umesh R Hadvani",
      image: doctorImages.ProfileImageMissing,
      title: "Consultant",
      specialty: "Anaesthesia, DA",
      experience: "15 Years",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 23,
      name: "Dr. Amit Agrawal",
      image: doctorImages.DrAmitAgrawal,
      title: "Consultant",
      specialty: "Orthopaedics, MS",
      experience: "25 Years",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 24,
      name: "Dr. Maharshi Bhatt",
      image: doctorImages.DrMaharshiBhatt,
      title: "Consultant",
      specialty: "Orthopaedics, MS",
      experience: "25 Years",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 25,
      name: "Dr. Tejas Patel",
      image: doctorImages.ProfileImageMissing,
      title: "Consultant",
      specialty: "Neuro Surgery, MS, MCh",
      experience: "N/A",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 26,
      name: "Dr. Mansi Shah",
      image: doctorImages.ProfileImageMissing,
      title: "Consultant",
      specialty: "Dermatology, MD, DVL",
      experience: "N/A",
      gender: "Female",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 27,
      name: "Dr. Vishal Vora",
      image: doctorImages.DrVishalVora,
      title: "Consultant",
      specialty: "Plastic Surgery, DNB",
      experience: "N/A",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 28,
      name: "Dr. Dhaval Bhadja (Patel)",
      image: doctorImages.DrDhavalBhadaja,
      title: "Consultant",
      specialty: "General Medicine, DNB",
      experience: "N/A",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 29,
      name: "Dr. Ravi Bokarvadia (Patel)",
      image: doctorImages.ProfileImageMissing,
      title: "Consultant",
      specialty: "Gastrology, MD, DNB",
      experience: "N/A",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 30,
      name: "Dr. Yash Gangadia",
      image: doctorImages.DrYashGangadia,
      title: "Consultant",
      specialty: "Gastrology, MD, DNB",
      experience: "7 Years",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 31,
      name: "Dr. Neil Palkhiwala",
      image: doctorImages.DrNeilPalkhivala,
      title: "Consultant",
      specialty: "Gastrology, MD, DM",
      experience: "N/A",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 32,
      name: "Dr. Chirag Patel",
      image: doctorImages.DrChiragMPatel,
      title: "Consultant",
      specialty: "Cardiology, MD, DM",
      experience: "N/A",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 33,
      name: "Dr. Jignesh Patel",
      image: doctorImages.DrJigneshPatel,
      title: "Consultant",
      specialty: "Cardiology, MD, DM",
      experience: "N/A",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 34,
      name: "Dr. Jigar Anandjiwala",
      image: doctorImages.DrJigarAnandjiwala,
      title: "Consultant",
      specialty: "Spine Surgery, D.ORTHO",
      experience: "N/A",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 35,
      name: "Dr. Rinku Trivedi",
      image: doctorImages.ProfileImageMissing,
      title: "Consultant",
      specialty: "Dental, BDS",
      experience: "N/A",
      gender: "Female",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 36,
      image: doctorImages.DrBhagyadhanPatel,
      name: "Dr. Bhagyadhan A Patel",
      title: "Consultant",
      specialty: "Neurology, MD, DNB",
      experience: "11 Years",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 37,
      image: doctorImages.DrChintanPrajapati,
      name: "Dr. Chintan Prajapati",
      title: "Consultant",
      specialty: "Neurology, MD, DM",
      experience: "N/A",
      location: "Vastral, Ahmedabad - India",
    },
    {
      id: 38,
      name: "Dr. Bhargav Prajapati",
      image: doctorImages.DrBhargavPrajapati,
      title: "Consultant",
      specialty: "Neurology, MD, DM",
      experience: "N/A",
      location: "Vastral, Ahmedabad - India",
    },
  ];
  const [headerData, setHeaderData] = React.useState();
  const [footerData, setFooterData] = React.useState();
  const [specialties, setSpecialties] = React.useState([]);

  const getData = async () => {
    try {
      const response = await getHeaderFooter();
      if (response.status == 200) {
        setHeaderData(response.data.responseBody.header);
        setFooterData(response.data.responseBody.footer);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  React.useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      if (isMounted) {
        await getData();
      }
    };

    fetchData();

    return () => {
      isMounted = false; // Cleanup function
    };
  }, []);

  useEffect(() => {
    getSpecialtiesDetails()
  }, [])

  const getSpecialtiesDetails = async () => {
    try {
      const resp = await getSpecialties();
      if (resp.status === 200) {
        setSpecialties(resp.data.responseBody);
      }
    } catch (error) {
      console.log("error", error)
    }
  }
  useEffect(() => {
    const removeHashFromUrl = () => {
      if (window.location.hash === "#top") {
        window.history.replaceState(null, "", window.location.pathname);
      }
    };

    // Add a hashchange event listener
    window.addEventListener("hashchange", removeHashFromUrl);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("hashchange", removeHashFromUrl);
    };
  }, []);

  return (
    <Router>
      <Header
        headerData={headerData}
        footerData={footerData}
      />
      <Navbar headerData={headerData} />
      <Routes>
        <Route path="/"
          element={
            <Home
              headerData={headerData}
              footerData={footerData}
              specialties={specialties}
            />}
        />
        <Route path="/aboutus"
          element={
            <AboutUs
              headerData={headerData}
              footerData={footerData}
            />}
        />
        <Route path="/specialties"
          element={
            <Specialties
              headerData={headerData}
              footerData={footerData}
            />}
        />
        <Route path="/contact"
          element={
            <Contact
              headerData={headerData}
              footerData={footerData}
            />}
        />
        <Route path="/doctor-list"
          element={
            <DoctorList
              doctors={doctors}
              headerData={headerData}
              footerData={footerData}
              specialties={specialties}
            />}
        />
        <Route
          path="/view-profile/:id"
          element={
            <ViewProfile
              doctors={doctors}
              headerData={headerData}
              footerData={footerData}
            />}
        />
        <Route path="/health-screening"
          element={<HealthScreen headerData={headerData} footerData={footerData} specialties={specialties} />}
        />
        <Route path="/health-checkup/:id"
          element={
            <HealthCheckDetails
              headerData={headerData}
              footerData={footerData}
            />}
        />
        <Route path="/health-specialist/:id"
          element={
            <SpecialistHistory
              doctors={doctors}
              headerData={headerData}
              footerData={footerData}
            />}
        />
    
        {/* <Route path="/terms-and-conditions" element={<TermsAndCondition />} />
        <Route path="/privacy-policy-default" element={<PrivacyPolicy />} /> */}
        <Route path="/:page" element={<CustomPage  footerData={footerData}/>}/>
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer
        headerData={headerData}
        footerData={footerData}
      />
    </Router>
  );
};

export default Routers;
