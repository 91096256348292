import React, { useEffect, useState } from "react";
import { Grid, Typography, Box } from "@mui/material";
import { images } from "../../Config/images";
import "../HomeBooking/HomeBooking.css";
import { useNavigate, useLocation } from "react-router-dom";

const HomeBooking = ({ noMargin, footerData }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isDoctorListActive, setIsDoctorListActive] = useState(false);
  const [isBookAppointmentListActive, setIsBookAppointmentListListActive] = useState(false);
  const [isContactActive, setIsContactActive] = useState(false);
  // const isDoctorListActive = location.pathname === "/doctor-list";
  // const isContactActive = location.pathname === "/contact";

  useEffect(() => {
    // Scroll based on navigation intent
    if (location.state?.section === "book-appointment-active") {
      setIsBookAppointmentListListActive(true);
      setIsDoctorListActive(false);
      setIsContactActive(false)
    } else if (location.state?.section === "doctor-list-active") {
      setIsDoctorListActive(true);
      setIsBookAppointmentListListActive(false);
      setIsContactActive(false)
    } else if (location.pathname === "/contact") {
      setIsContactActive(true);
      setIsBookAppointmentListListActive(false);
      setIsDoctorListActive(false);
    }


  }, [location.state]);
  
  return (
    <div>
      <div className={`box-postions ${noMargin ? "no-margin" : ""}`}>
        <Grid container spacing={2} className="booking-home">

          <Grid item xs={6} sm={6} md={4} lg={3}>
            <Box className="box blue-box"
              sx={{
                backgroundColor: isBookAppointmentListActive ? "#01adec" : "#354895",
              }}
              onClick={() => navigate("/doctor-list", { state: { section: "book-appointment-active" } })}>
              <img
                src={images.calaender}
                alt="Book An Appointment"
                className="box-image"
              />
              <div className="heading-box">
                <Typography variant="h6" className="box-heading">
                  Book An
                  <br />
                  Appointment
                </Typography>
              </div>
            </Box>
          </Grid>

          <Grid item xs={6} sm={6} md={4} lg={3}>
            <Box className="box" onClick={() => navigate("/doctor-list", { state: { section: "doctor-list-active" } })}
              sx={{
                backgroundColor: isDoctorListActive ? "#01adec" : "#354895",
              }}
            >
              <img src={images.contact1} alt="Doctors" className="box-image" />
              <div className="heading-box">
                <Typography variant="h6" className="box-heading">
                  Doctors
                  <br />
                  <span className="box-text">For your Health Checkup</span>
                </Typography>
              </div>
            </Box>
          </Grid>


          <Grid item xs={6} sm={6} md={4} lg={3}>
            <Box className="box"
              sx={{
                backgroundColor: isContactActive ? "#01adec" : "#354895",
              }}
              onClick={() => navigate("/contact")}>
              <img
                src={images.builders}
                alt="Our Hospitals"
                className="box-image"
              />
              <div className="heading-box">
                <Typography variant="h6" className="box-heading">
                  Our Hospitals
                  <br />
                  <span className="box-text">Multispecialty Services</span>
                </Typography>
              </div>
            </Box>
          </Grid>


          <Grid item xs={6} sm={6} md={4} lg={3}>
            <a className="box emergency-box" href={`tel:${footerData && footerData?.emergency_number}`}>
              <img
                src={images.appoitment}
                alt="Emergency Call"
                className="box-image"
              />
              <div className="heading-box">
                <Typography variant="h6" className="box-heading">
                  Emergency Call
                  <br />
                  <span className="box-text">{footerData && footerData.emergency_number}</span>
                  <br />
                  <span className="call-box" style={{ fontSize: "12px" }}>
                    Call for enquiry
                  </span>
                </Typography>
              </div>
            </a>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default HomeBooking;
