import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Grid,
  Box,
} from "@mui/material";
import { healthPackages } from "../../Config/images";
import "./HealthPackages.css";
import { useNavigate } from "react-router-dom";
import { getHealthScreenInfo } from "../../Services/services";

const HealthCheckCard = ({ healthScreenData }) => {
  const navigate = useNavigate();


  const handleCheckupDetails = async (id) => {
    navigate(`/health-checkup/${id}`)

  }
  return (
    <Grid container spacing={2}>
      {healthScreenData?.data?.length > 0 ? healthScreenData?.data?.map((e) => {
        return (
          <Grid item xs={12} sm={6} md={6}>
            <div className="d-flex healthcheck-sec">
              <img src={e.image} alt="" className="healthcheck-data-image" />
              <div className="femina-health-check-data">
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "bold", fontSize: "20px" }}
                >
                  {e.name}
                </Typography>

                {/* <Typography
                  variant="body2"
                  sx={{
                    color: "#011632",
                    mt: 1,
                    fontSize: "14px",
                    fontWeight: 700,
                  }}
                >
                  Available At:
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ mb: 1, fontSize: "16px", color: "#011632" }}
                >
                  {e.location ? e.location : "-"}
                </Typography> */}

                <Typography
                  variant="body2"
                  sx={{
                    color: "#011632",
                    mt: 1,
                    fontSize: "14px",
                    fontWeight: 700,
                  }}
                >
                  Tests Include:
                </Typography>
                <Typography variant="body2" sx={{ fontSize: "16px" }}>
                  {e.total_test ? e.total_test : "-"}
                </Typography>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: 700,
                        color: "#011632",
                        mt: 2,
                        fontSize: "14px",
                      }}
                    > 
                      Price:
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        color: "#011632",
                        mb: 1,
                        fontWeight: "bold",
                        fontSize: "20px",
                      }}
                    >
                      {e.price ? `₹${e.price}/-` : "-"}
                    </Typography>
                  </div>
                  <Button variant="outlined" className="general-btn" style={{borderRadius:"4px"}}>
                    {e.package_type ? e.package_type : "-"}
                  </Button>
                </div>
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    alignItems: "center",

                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <button
                    variant="contained"
                    className="view-more-btn"
                    onClick={() => navigate(`/health-checkup/${e.id}`, { state: { section: "view-more" } })}
                  >
                    View More
                  </button>

                  <button
                    variant="contained"
                    color="secondary"
                    className="book-appo-btn"
                    onClick={() => navigate(`/health-checkup/${e.id}`, { state: { section: "book-appointment" } })}
                  >
                    Book A Health Check
                  </button>
                </Box>
              </div>
            </div>
          </Grid>
        );
      }) :
        <h2 className="pt-4" style={{ marginLeft: "auto", marginRight: "auto" }}>
          No Data Found
        </h2>
      }
    </Grid>
  );
};

export default HealthCheckCard;
