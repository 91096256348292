export const specialitiesIcons = {
  surgeryRoom: require("../assets/images/specialities-icons/surgery-room.png"),
  ctScan: require("../assets/images/specialities-icons/ct-scan.png"),
  gastro: require("../assets/images/specialities-icons/gastro.png"),
  kidneys: require("../assets/images/specialities-icons/kidneys.png"),
  medicine: require("../assets/images/specialities-icons/medicine.png"),
  mentalState: require("../assets/images/specialities-icons/mental-state.png"),
  painInJoints: require("../assets/images/specialities-icons/pain-in-joints.png"),
  psychology: require("../assets/images/specialities-icons/psychology.png"),
  roboticSurgery: require("../assets/images/specialities-icons/robotic-surgery.png"),
  medical: require("../assets/images/specialities-icons/medical.png"),
};

export const specialitiesIconsNew = {
  dermatologyAndCosmetology: require("../assets/images/specialities-icons/new/dermatology and cosmetology 2.png"),
  diabetology: require("../assets/images/specialities-icons/new/diabetology 2.png"),
  endocrinology: require("../assets/images/specialities-icons/new/endocrinology 2.png"),
  ent: require("../assets/images/specialities-icons/new/ENT ( ear, nose and throat) 2.png"),
  generalAndMinimalAccessSurgery: require("../assets/images/specialities-icons/new/General and minimal access surgery 2.png"),
  generalMedicine: require("../assets/images/specialities-icons/new/general medicine 2.png"),
  hpbSurgery: require("../assets/images/specialities-icons/new/HPB surgery 2.png"),
  infectiousDisease: require("../assets/images/specialities-icons/new/Infectious disease 2.png"),
  interventionalRadiology: require("../assets/images/specialities-icons/new/Interventional radiology 2.png"),
  laboratory: require("../assets/images/specialities-icons/new/laboratory 2.png"),
  nephrology: require("../assets/images/specialities-icons/new/nephrology 2.png"),
  neuroAndSpineSurgery: require("../assets/images/specialities-icons/new/neuro and spine surgery 2.png"),
  nutritionAndDietitics: require("../assets/images/specialities-icons/new/nutrition and dietitics 2.png"),
  obstetricsAndGynaecology: require("../assets/images/specialities-icons/new/obstetrics and gynaecology 2.png"),
  oncology: require("../assets/images/specialities-icons/new/oncology 2.png"),
  ophthalmology: require("../assets/images/specialities-icons/new/ophthalmology 2.png"),
  orthopaedicsAndJointReplacement: require("../assets/images/specialities-icons/new/orthopaedics & joint replacement.png"),
  paediatrics: require("../assets/images/specialities-icons/new/paediatrics 2.png"),
  painManagement: require("../assets/images/specialities-icons/new/pain management 2.png"),
  physiotherapy: require("../assets/images/specialities-icons/new/physiotherapy 2.png"),
  plasticCosmeticReconstructiveSurgery: require("../assets/images/specialities-icons/new/plasticcosmeicreconstructivesurgery 2.png"),
  psychiatry: require("../assets/images/specialities-icons/new/psychiatry 2.png"),
  psychological: require("../assets/images/specialities-icons/new/psychological 2.png"),
  pulmonologyInterventionalPulmonology: require("../assets/images/specialities-icons/new/pulmonologyinterventionalpulmonology 2.png"),
  rheumatology: require("../assets/images/specialities-icons/new/rheumatology 2.png"),
  surgicalGastroenterology: require("../assets/images/specialities-icons/new/oncology 2.png"),
  surgicalOncology: require("../assets/images/specialities-icons/new/surgical oncology.png"),
  urogynaecology: require("../assets/images/specialities-icons/new/urogynaecology 2.png"),
  urosurgery: require("../assets/images/specialities-icons/new/urosurgery.png"),
  vascularAndEndovascularSurgery: require("../assets/images/specialities-icons/new/vascularandendovacularsurgery 2.png"),
  aesthetics: require("../assets/images/specialities-icons/new/aesthetics.png"),
  ambulance: require("../assets/images/specialities-icons/new/ambulance 2.png"),
  anesthesiology: require("../assets/images/specialities-icons/new/anesthesiology 2.png"),
  audiologyAndSpeechTherapy: require("../assets/images/specialities-icons/new/audiology and speech therapy 2.png"),
  cancer: require("../assets/images/specialities-icons/new/cancer 2.png"),
  cardiology: require("../assets/images/specialities-icons/new/cardiology 2.png"),
  cardiovascularSurgery: require("../assets/images/specialities-icons/new/cardiovascular surgery 2.png"),
  cranioMaxillofacialSurgery: require("../assets/images/specialities-icons/new/cranio maxillofacial surgery 2.png"),
  criticalCare: require("../assets/images/specialities-icons/new/crical care 2.png"),
  dentalSciences: require("../assets/images/specialities-icons/new/dental sciences 2.png"),
  surgicalGastroenterology2: require("../assets/images/specialities-icons/new/surgical gastroenterology.png"),
};

export const images = {
  contact: require("../assets/images/call-header.png"),
  email: require("../assets/images/email-header.png"),
  globallogo: require("../assets/images/hospital-logo.png"),
  globallogo2x: require("../assets/images/global-hospital-logo-2x.png"),
  doctores: require("../assets/images/doctor-with-co-workers-analyzing-x-ray.png"),
  doctorfemale: require("../assets/images/dotore-female.png"),
  emargancy: require("../assets/images/booking.png"),
  phoneCall: require("../assets/images/phoneCall.png"),
  whatshap: require("../assets/images/whatsapp (2) 1 (1).png"),
  footerlogo: require("../assets/images/global-hospital-white.png"),
  slider1: require("../assets/images/slider.png"),
  calaender: require("../assets/images/calender.png"),
  contact1: require("../assets/images/contact.png"),
  builders: require("../assets/images/builder.png"),
  appoitment: require("../assets/images/appoitment.png"),
  facial: require("../assets/images/facial.png"),
  massage: require("../assets/images/massage.png"),
  joint: require("../assets/images/joint.png"),
  skin: require("../assets/images/skin.png"),
  cardiogram: require("../assets/images/cardiogram.png"),
  medicalstaff: require("../assets/images/medical-staff.png"),
  hospitalbed: require("../assets/images/hospital-bed.png"),
  icubed: require("../assets/images/icu.png"),
  keyreactgle: require("../assets/images/Rectangle 655.png"),
  surgerroom: require("../assets/images/surgery-room.png"),
  reactangle655: require("../assets/images/Rectangle 645.png"),
  hospitalabout: require("../assets/images/hospital-about.png"),
  union: require("../assets/images/Union.png"),
  maskimg: require("../assets/images/Mask group.png"),
  doctore1: require("../assets/images/doctor-1.png"),
  logomake: require("../assets/images/logo_make.png"),
  bloodpressur: require("../assets/images/blood-pressure-female-patient.png"),
  oprationroom: require("../assets/images/operating-room.png"),
  femaleworker: require("../assets/images/female-working-nurse.png"),
  surgeryopration: require("../assets/images/surgery-operating-theatre.png"),
  workrsurgical: require("../assets/images/worker-surgical-uniform-operation-theater.png"),
  waves: require("../assets/images/desing-waves.png"),
  contactSlider: require("../assets/images/contactusSlider.jpg"),
  locationInfo: require("../assets/images/location.png"),
  customer: require("../assets/images/customer.png"),
  openHours: require("../assets/images/openhours.png"),
  emailData: require("../assets/images/mail.png"),
  patients: require("../assets/images/patient.png"),
  planet: require("../assets/images/planet.png"),
  shareholders: require("../assets/images/shareholders.png"),
  people: require("../assets/images/people.png"),
  partners: require("../assets/images/partner.png"),
  communities: require("../assets/images/Communities.png"),
  quote: require("../assets/images/quote.png"),
  quote1: require("../assets/images/quote1.png"),
  femaleDoctor: require("../assets/images/Doctor.png"),
  excellenceInfra: require("../assets/images/Excellence-infrastructure.png"),
  patientwell: require("../assets/images/patient-well-being.png"),
  safeHealing: require("../assets/images/safe-healing.png"),
  excellence: require("../assets/images/Excellence.png"),
  feedback: require("../assets/images/Feedback.png"),
  contactslider: require("../assets/images/sliderData.jpg"),
  doctorList: require("../assets/images/nurse-hospital.png"),
  userLocation: require("../assets/images/user-location.png"),
  userLocationWhite: require("../assets/images/user_location_white.png"),
  doctorList1: require("../assets/images/Dr-Jagdish.png"),
  doctorList2: require("../assets/images/Dr-Neel.png"),
  doctorList6: require("../assets/images/Dr-Khushali.png"),
  doctorList7: require("../assets/images/Dr-Mukul.png"),
  doctorList4: require("../assets/images/Dr-Vrooti.png"),
  doctorList5: require("../assets/images/Dr-Zinkal.png"),
  doctorList3: require("../assets/images/nurse-hospital.png"),
  doctorList8: require("../assets/images/nurse-portrait-hospital.png"),
  sliderNew1: require("../assets/images/slider-1.jpg"),
  sliderNew2: require("../assets/images/slider-2.jpg"),
  rightIconDoctor: require("../assets/images/right-icon-doctor.png"),
  leftIconDoctor: require("../assets/images/left-icon-doctor.png"),
};

export const doctorImages = {
  ProfileImageMissing: require("../assets/images/doctor-images/profile-image-missing.png"),
  DrBrijeshPatel223X223: require("../assets/images/doctor-images/DR BRIJESH PATEL-223X223.png"),
  DrBhargavPrajapati: require("../assets/images/doctor-images/Dr.Bhargav Prajapti.png"),
  DrKevalPatel: require("../assets/images/doctor-images/Dr.Keval Patel.png"),
  DrKhushaliPatel: require("../assets/images/doctor-images/Dr.Khushali Patel.png"),
  DrMukulTrivedi: require("../assets/images/doctor-images/Dr.Mukul Trivedi.png"),
  DrNeelPatel: require("../assets/images/doctor-images/Dr.Neel Patel.png"),
  DrNeilPalkhivala: require("../assets/images/doctor-images/Dr.Neil Palkhivala.png"),
  DrRushabhAgrawal: require("../assets/images/doctor-images/Dr.Rushabh Agrawal.png"),
  DrJigarAnandjiwala: require("../assets/images/doctor-images/Dr Jigar Anandjiwala.png"),
  DrBrijeshPatel: require("../assets/images/doctor-images/DR BRIJESH PATEL.png"),
  DrDhavalBhadaja: require("../assets/images/doctor-images/Dr Dhaval Bhadaja (Patel).png"),
  DrDhruvPatel: require("../assets/images/doctor-images/Dr Dhruv Patel.png"),
  DrHasmukhAgrawal: require("../assets/images/doctor-images/Dr Hasmukh Agrawal.png"),
  DrJigarPatel: require("../assets/images/doctor-images/Dr Jigar Patel.png"),
  DrKamleshShah: require("../assets/images/doctor-images/Dr Kamlesh Shah.png"),
  DrRushiDesai: require("../assets/images/doctor-images/Dr Rushi Desai.png"),
  DrAmitAgrawal: require("../assets/images/doctor-images/Dr. Amit Agrawal.png"),
  DrChintanPrajapati: require("../assets/images/doctor-images/DR. Chintan Prajapti.png"),
  DrLaxmiAgrawal: require("../assets/images/doctor-images/Dr. Laxmi Agrawal.png"),
  DrMaharshiBhatt: require("../assets/images/doctor-images/Dr. Maharshi Bhatt.png"),
  DrVarunPatel: require("../assets/images/doctor-images/Dr. Varun Patel.png"),
  DrZinkalPatel: require("../assets/images/doctor-images/Dr. Zinkal Patel.png"),
  DrBhagyadhanPatel: require("../assets/images/doctor-images/Dr.Bhagyadhan Patel.png"),
  DrJagdishBPatel: require("../assets/images/doctor-images/Dr. Jagdish B Patel.png"),
  DrJigneshPatel: require("../assets/images/doctor-images/Dr. Jignesh Patel.png"),
  DrYashGangadia: require("../assets/images/doctor-images/Dr. Yash Gangadia.png"),
  DrAnandPatel: require("../assets/images/doctor-images/Dr Anand Patel.png"),
  // DrNikhilModi: require("../assets/images/doctor-images/Dr Nikhil Modi.png"),
  DrChiragMPatel: require("../assets/images/doctor-images/Dr.Chirag M Patel.png"),
  DrMeetPatel: require("../assets/images/doctor-images/Dr.Meet Patel.png"),
  DrMiteshPatel: require("../assets/images/doctor-images/Dr.Mitesh Patel.png"),
  DrShrineshAgrawal: require("../assets/images/doctor-images/Dr.Shrinesh Agrawal.png"),
  DrVishalVora: require("../assets/images/doctor-images/Dr.Vishal Vora.png"),
};

export const healthPackages ={
  healthCheck : require("../assets/images/health-screen/Healthcheck.png"),
  checkIcon : require("../assets/images/health-screen/Check.png"),
  HealthDetails : require("../assets/images/health-screen/HealthDetail.png"),
  bestEnt: require("../assets/images/health-screen/best-ent.png"),
  healthCheckData: require("../assets/images/health-screen/health-spec.png"),
  rightArrow : require("../assets/images/right-arrow.png")
}

export const whyChooseUs = {
  image1: require("../assets/images/whyChooseUs-image1.jpeg"),
  image2: require("../assets/images/whyChooseUs-image2.jpeg"),
  image3: require("../assets/images/whyChooseUs-image3.jpeg"),
  }

  export const banner={
    aboutUsBanner : require("../assets/images/Banner/about-us-banner.png"),
    specialistBanner : require("../assets/images/Banner/specialist-banner.png"),
    doctorListBanner: require("../assets/images/Banner/doctor-list-banner.png"),
    doctorDetailsBanner : require("../assets/images/Banner/doctor-details-banner.png"),
    healthScreenBanner : require("../assets/images/Banner/health-screen-banner.png"),
    specilistDetailBanner: require("../assets/images/Banner/specialist-detail-banner.png")
  }