import React, { useState, useRef, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Avatar,
  Box,
  Grid2,
  Container,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  Pagination,
  Chip,
} from "@mui/material";
import HomeBooking from "../../componant/HomeBooking/HomeBooking";
import { images, doctorImages, healthPackages, banner } from "../../Config/images";
import "../DoctorList/DoctorList.css";
import CommonBreadcrumbs from "../../componant/Breadcrumb/Breadcrumb";
import SearchBar from "material-ui-search-bar";
import Booking from "../../componant/Booking/Booking";
import { getDoctorInfo } from "../../Services/services";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AppointmentPopUp from "../../componant/AppointmentPopUp/AppointmentPopUp";
import "../ViewProfile/ViewProfile.css";

const ViewProfile = ({
  doctors,
  headerData,
  footerData,
}) => {
  const { id } = useParams();
  const topRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();

  const doctorFindWithId = doctors.find((doctor) => doctor.id === Number(id));
  const [doctorsDetails, setDoctorsDetails] = useState([]);
  const breadcrumbsData = [
    { label: "Home", url: "/" },
    {
      label: "Doctor Details",
      url: `/doctor-list`,
    },
    {
      label: doctorsDetails && doctorsDetails.name,
      url: `/view-profile/${id}`,
    },
  ];

  const [openPopup, setOpenPopup] = useState(false);
  const getDoctorsDetails = async () => {
    try {

      const response = await getDoctorInfo(id);
      if (response.data.status === true) {
        setDoctorsDetails(response.data.responseBody);
      }
    } catch (error) {
      console.log("error", error)
    }
  }
  useEffect(() => {
    getDoctorsDetails();
  }, [])
  const [showFullText, setShowFullText] = useState(false);
  const [showAllExperience, setShowAllExperience] = useState(false);
  const [showAllAchievements, setShowAllAchievements] = useState(false);
  const [showAllQualifications, setShowAllQualifications] = useState(false);
  const [showAllPublications, setShowAllPublications] = useState(false);

  useEffect(() => {
    const topElement = topRef.current;

    if (topElement) {
      const navbarHeight = 800; // Replace with your navbar's height in pixels
      const topPosition = topElement.offsetTop - navbarHeight;

      window.scrollTo({
        top: topPosition,
        behavior: "smooth",
      });
    }
  }, []);


  useEffect(() => {
    // Scroll based on navigation intent
    if (location.state?.section === "book-appointment") {
      setOpenPopup(true);
      // remove the section from location.state without navigation
      navigate(location.pathname, { replace: true, })
    }

  }, [location.state]);

  return (
    <>
      <div>
        <div className="header-image" ref={topRef}>
          <div className="absolute-image-container">
            <img
              src={banner.doctorDetailsBanner}
              alt="Doctors"
              className="absolute-image"
            />
            <div className="image-text-about">
              <h1 className="text-about">Doctor Details</h1>
              <p className="text-health">Meet our specialist</p>
              <div className="breadcrumb">
                <CommonBreadcrumbs items={breadcrumbsData} separator="›" />
              </div>
            </div>
          </div>
        </div>
        <div>
          <HomeBooking noMargin footerData={footerData} />
        </div>
        <div className="commit-sec mb-5">
          <div className="doctor-title mt-3">Doctor Profile</div>
          <div className="pt-5">
            <div
              className="d-flex gap-5 doct-pro-sec"
            // style={{ flexWrap: "wrap" }}
            >
              <div>
                <div>
                  <img
                    className="view-profile-img"
                    src={doctorsDetails?.image}
                    alt="doctor-img"
                  />
                </div>
                <button
                  variant="contained"
                  size="small"
                  className="book-appoinment-btn-view mt-3 mx-4"
                  onClick={() => setOpenPopup(true)}
                >
                  Book An Appointment
                </button>
              </div>
              <div>
                <Box
                  // sx={{
                  //   width: "650px",
                  // }}
                  className="profile-box"
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4} md={3} lg={3}>
                      <Typography
                        variant="subtitle2"
                        sx={{ fontWeight: "bold" }}
                        className="profile-name"
                      >
                        Name:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8} md={9} lg={9} className="doct-pro">
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: "bold" }}
                        className="doct-name"
                      >
                        {doctorsDetails?.name}
                      </Typography>
                      <Typography variant="body2" sx={{ color: "gray" }}>
                        {doctorsDetails.type}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={4} md={3} lg={3}>
                      <Typography
                        variant="subtitle2"
                        sx={{ fontWeight: "bold" }}
                        className="profile-name"
                      >
                        Speciality:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8} md={9}  lg={9} className="doct-pro">
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
                        {doctorsDetails?.specialties?.split(",").map((speciality, index) => (
                          <Chip
                            key={index}
                            label={speciality.trim()}
                            className="chip-label"
                            sx={{
                              borderRadius: "5px",
                            }}
                          />
                        ))}
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={4} md={3} lg={3}>
                      <Typography
                        variant="subtitle2"
                        sx={{ fontWeight: "bold" }}
                        className="profile-name"
                      >
                        Qualification:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8} md={9} lg={9} className="doct-pro">
                      <Box sx={{ display: "flex", gap: "8px" }}>
                        {doctorsDetails?.qualifications?.map((qualification, index) => (
                          <Chip
                            key={index}
                            label={qualification.qualification_name}
                            className="chip-label"
                            sx={{ borderRadius: "5px", marginRight: "8px" }}
                          />
                        ))}
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={4} md={3} lg={3}>
                      <Typography
                        variant="subtitle2"
                        sx={{ fontWeight: "bold" }}
                        className="profile-name"
                      >
                        Experience:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8} md={9} lg={9} className="doct-pro">
                      <Chip
                        label={doctorsDetails.experience}
                        style={{ fontWeight: 700 }}
                        className="chip-label"
                        sx={{ borderRadius: "5px" }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={4} md={3} lg={3}>
                      <Typography
                        variant="subtitle2"
                        sx={{ fontWeight: "bold" }}
                        className="profile-name"
                      >
                        Gender:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8} md={9} lg={9} className="doct-pro">
                      <Chip
                        label={doctorsDetails?.gender}
                        className="chip-label"
                        sx={{ borderRadius: "5px" }}
                      />
                    </Grid>

                    {/* Language Spoken */}
                    <Grid item xs={12} sm={4} md={3} lg={3}>
                      <Typography
                        variant="subtitle2"
                        sx={{ fontWeight: "bold" }}
                        className="profile-name"
                      >
                        Language Spoken:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8} md={9} lg={9} className="doct-pro">
                      <Box sx={{ display: "flex", gap: "8px" }}>
                        {doctorsDetails?.languages?.map((item, index) => (
                          <Chip
                            key={index}
                            label={item.language}
                            className="chip-label"
                            sx={{ borderRadius: "5px", marginRight: "8px" }}
                          />
                        ))}
                      </Box>
                    </Grid>

                    {/* Location */}
                    {/* <Grid item xs={12} md={3} lg={3}>
                      <Typography
                        variant="subtitle2"
                        sx={{ fontWeight: "bold" }}
                        className="profile-name"
                      >
                        Location:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={9} lg={9} className="doct-pro">
                      <Chip
                        label={doctorsDetails?.hospitals}
                        className="chip-label"
                        sx={{ borderRadius: "5px" }}
                      />
                    </Grid> */}

                    {/* Consultation Charges */}
                    {/* <Grid item xs={12} md={3} lg={3}>
                      <Typography
                        variant="subtitle2"
                        sx={{ fontWeight: "bold" }}
                        className="profile-name"
                      >
                        Consultation Charges:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={9} lg={9} className="doct-pro">
                      <Chip
                        label={doctorsDetails?.consultation_charges}
                        className="chip-label"
                        sx={{ borderRadius: "5px" }}
                      />
                    </Grid> */}
                  </Grid>
                </Box>
              </div>
            </div>
          </div>
          {/* About Section */}
          <div className="about-section mt-4">
            <h2 className="about-heading">About</h2>
            <p className="about-description">
              {doctorsDetails?.about?.length > 200 // Check if the text is longer than 200 characters
                ? showFullText
                  ? doctorsDetails?.about // Show full text
                  : `${doctorsDetails?.about.substring(0, 200)}...` // Show limited text
                : doctorsDetails?.about} {/* If text is less than 200, display it as is */}
            </p>
            {doctorsDetails?.about?.length > 200 && ( // Only show "More/Less" if the text is longer than 200 characters
              <p
                className="about-more"
                onClick={() => setShowFullText(!showFullText)} // Toggle the state
              >
                {showFullText ? "+ Less" : "+ More"} {/* Toggle button text */}
              </p>
            )}
          </div>
          {/* Experience Section */}
          <div className="about-section">
            <h2 className="about-heading">Experience</h2>
            <div className="about-description">
              {doctorsDetails?.experience_details?.slice(0, showAllExperience ? doctorsDetails.experience_details.length : 2).map((experience, index) => (
                <div key={index} style={{ display: "flex", gap: "10px", marginBottom: "10px" }}>
                  <img
                    src={healthPackages.checkIcon}
                    alt=""
                    style={{
                      height: "20px",
                      width: "20px",
                      marginTop: "3px",
                    }}
                  />
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <p style={{ marginBottom: "2px", fontWeight: 700 }}>{experience.time}</p>
                    <p style={{ margin: "0px" }}>{experience.title}</p>
                  </div>
                </div>
              ))}
            </div>
            {doctorsDetails?.experience_details?.length > 2 && ( // Only show "More/Less" if there are more than 2 items
              <div
                className="about-more"
                onClick={() => setShowAllExperience(!showAllExperience)} // Toggle the state
              >
                {showAllExperience ? "+ Less" : "+ More"} {/* Toggle button text */}
              </div>
            )}
          </div>
          {/* Achievements Section */}
          <div className="about-section">
            <h2 className="about-heading">Achievements</h2>
            <div className="about-description">
              {doctorsDetails?.achievements?.slice(0, showAllAchievements ? doctorsDetails.achievements.length : 2).map((achievement, index) => (
                <div key={index} style={{ display: "flex", gap: "10px" }}>
                  <img
                    src={healthPackages.checkIcon}
                    alt=""
                    style={{
                      height: "20px",
                      width: "20px",
                      marginTop: "3px",
                    }}
                  />
                  <p>{achievement}</p>
                </div>
              ))}
            </div>
            {doctorsDetails?.achievements?.length > 2 && ( // Only show "More/Less" if there are more than 2 items
              <div
                className="about-more"
                onClick={() => setShowAllAchievements(!showAllAchievements)} // Toggle the state
              >
                {showAllAchievements ? "+ Less" : "+ More"} {/* Toggle button text */}
              </div>
            )}
          </div>
          {/* Qualifications Section */}
          <div className="about-section">
            <h2 className="about-heading">Qualifications</h2>
            <div className="about-description">
              {doctorsDetails?.qualifications?.slice(0, showAllQualifications ? doctorsDetails.qualifications.length : 2).map((qualification, index) => (
                <div key={index} style={{ display: "flex", gap: "10px" }}>
                  <img
                    src={healthPackages.checkIcon}
                    alt=""
                    style={{
                      height: "20px",
                      width: "20px",
                      marginTop: "3px",
                    }}
                  />
                  <p>{qualification.qualification_name}</p>
                </div>
              ))}
            </div>
            {doctorsDetails?.qualifications?.length > 2 && ( // Only show "More/Less" if there are more than 2 items
              <div
                className="about-more"
                onClick={() => setShowAllQualifications(!showAllQualifications)} // Toggle the state
              >
                {showAllQualifications ? "+ Less" : "+ More"} {/* Toggle button text */}
              </div>
            )}
          </div>
          {/* Publications Section */}
          <div className="about-section">
            <h2 className="about-heading">Publications</h2>
            <div className="about-description">
              {doctorsDetails?.publications?.slice(0, showAllPublications ? doctorsDetails.publications.length : 2).map((publications, index) => (
                <div key={index} style={{ display: "flex", gap: "10px" }}>
                  <img
                    src={healthPackages.checkIcon}
                    alt=""
                    style={{
                      height: "20px",
                      width: "20px",
                      marginTop: "3px",
                    }}
                  />
                  <p>{publications}</p>
                </div>
              ))}
            </div>
            {doctorsDetails?.publications?.length > 2 && ( // Only show "More/Less" if there are more than 2 items
              <div
                className="about-more"
                onClick={() => setShowAllPublications(!showAllPublications)} // Toggle the state
              >
                {showAllPublications ? "+ Less" : "+ More"} {/* Toggle button text */}
              </div>
            )}
          </div>


          <AppointmentPopUp
            open={openPopup}
            onClose={() => setOpenPopup(false)}
            doctor_id={id}
          />
        </div>
        <div>
          <Booking
            headerData={headerData}
            footerData={footerData}
          />
        </div>
      </div>
    </>
  );
};

export default ViewProfile;
