import React, { useState, useEffect, useRef } from "react";
import CommonBreadcrumbs from '../Breadcrumb/Breadcrumb';
import { useParams } from 'react-router-dom';
import { getCustomAPIData } from "../../Services/services";
import { images } from "../../Config/images";
import HomeBooking from "../HomeBooking/HomeBooking";
import NotFound from "../../pages/404NotFound/NotFound";

const CustomPage = ({ footerData }) => {

    const topRef = useRef(null);

    const slug = useParams();
    const [customPageData, setCustomPageData] = useState([]);
    const [loading, setLoading] = useState(true);
    const fetchData = async () => {
        const params = {
            type: "footer",
            slug: slug.page,
        };
        setLoading(true);
        try {
            const resp = await getCustomAPIData({ params });
            console.log(resp);
            setCustomPageData(resp.data.responseBody[0]);
            setLoading(false);

        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        fetchData();
    }, []);


    useEffect(() => {
        const topElement = topRef.current;

        if (topElement) {
            const navbarHeight = 800; // Replace with your navbar's height in pixels
            const topPosition = topElement.offsetTop - navbarHeight;

            window.scrollTo({
                top: topPosition,
                behavior: "smooth",
            });
        }
    }, []);

    if (!customPageData || !customPageData.slug || customPageData.slug !== slug.page) {
        return <NotFound />;
    }
    const breadcrumbsData = [
        { label: "Home", url: "/" },
        {
            label: customPageData?.title,
            url: `/${slug.page}`,
        },
    ];

    return (
        <>

            <div className="header-image" ref={topRef}>
                <div className="absolute-image-container">
                    <img
                        src={images.contactslider} // Replace with your image source
                        alt="Doctors"
                        className="absolute-image"
                    />
                    <div className="image-text-about">
                        <h1 className="text-about">{customPageData?.title}</h1>
                        <div className="breadcrumb">
                            <CommonBreadcrumbs items={breadcrumbsData} separator="›" />
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <HomeBooking noMargin footerData={footerData} />
            </div>

            <div className="privacy-container" ref={topRef}>
                <header className="header-privacy">
                    <h1>{customPageData?.title}</h1>
                </header>
                <main className="container-fluid privacy-content">
                    <div className="container-1 mt-5 mb-5">
                        <div
                            className="customepage111"
                            dangerouslySetInnerHTML={{ __html: customPageData.content }}
                        />
                    </div>
                </main>
            </div>



        </>
    );
};

export default CustomPage;