import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { FiAlignRight } from "react-icons/fi";
import { IoMdClose } from "react-icons/io";
import { BiSearch } from "react-icons/bi";
import { Container } from "@mui/material";
import "./Navbar.css";
import { images, specialitiesIcons } from "../../Config/images";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

function Navbar({ headerData }) {
  const [containerClass, setContainerClass] = useState("container-fluid");
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const [activeLink, setActiveLink] = useState("home")
  // Handle container class on resize
  const handleResize = () => {
    setContainerClass(
      window.innerWidth >= 1200 ? "container" : "container-fluid"
    );
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Toggle overlay
  const toggleOverlay = () => {
    setIsOverlayVisible(!isOverlayVisible);
  };

  const handleCloseDropdown = () => {
    const dropdown = document.getElementById("navbarDropdown");
    if (dropdown) {
      const dropdownInstance = window.bootstrap.Dropdown.getInstance(dropdown);
      if (dropdownInstance) {
        dropdownInstance.hide();
      }
    }
  };

  // Close offcanvas menu
  const closeOffcanvas = () => {
    const offcanvasElement = document.querySelector(".offcanvas");
    const offcanvasInstance =
      window.bootstrap.Offcanvas.getInstance(offcanvasElement);
    offcanvasInstance.hide();
  };

  // Handle navigation and active link
  const handleNavLinkClick = (path) => {
    navigate(path);
    closeOffcanvas();
  };
  
  useEffect(() => {
    const path = location.pathname;
  
    if (path === "/aboutus") {
      setActiveLink("about");
    } else if (path === "/specialties" || path.includes("health-specialist")) {
      setActiveLink("specialties");
    } else if (path === "/health-screening" || path.includes("health-checkup")) {
      setActiveLink("healthScreen");
    } else if (path === "/contact") {
      setActiveLink("contact");
    } else {
      setActiveLink("home"); // Default to home
    }
  }, [location.pathname]);
  

  const CustomToolTip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#fff",
      color: '#354895',
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      fontSize: 11,
      fontSize: "16px",
      Padding: "20px"
    },
  }));

  return (
    <>
      <nav className="navbar">
        <div className="navbar-right-wrap">
          <NavLink className="navbar-brand" to="/">
            <img
              // src={images.globallogo2x}
              src={headerData?.header_image}
              alt="global-hospital-logo"
              className="navbar-logo"
            />
          </NavLink>
        </div>
        <div className="navbar-search-icon">
          <button
            className="navbar-toggler d-xl-none"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
          >
            <FiAlignRight className="toggler-icon" />
          </button>
        </div>
        <div className="navbar-wrap">
          <div className="navbar-text1 nav-elements">
            <ul className="nav-link-wrap">
              <li className={`nav-item dropdown ${activeLink === "about" ? "nav-item-active" : ""}`}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <NavLink
                    className="nav-link"
                    to="/aboutus"
                    style={{
                      marginRight: "8px",
                      color: activeLink === "about" ? "#00aff0" : ""
                    }}
                  >
                    ABOUT
                  </NavLink>
                  {/* <button
                    className="btn btn-link dropdown-toggle"
                    id="navbarDropdown"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{
                      padding: 0,
                      margin: 0,
                      fontSize: "10px",
                      border: "none",
                      backgroundColor: "transparent",
                      cursor: "pointer",
                    }}
                  >
                    <i className="fa-solid fa-chevron-down"></i>
                  </button> */}
                </div>

                {/* <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <NavLink
                      className="dropdown-item"
                      to="/division1"
                      onClick={() => handleCloseDropdown()}
                    >
                      Division 1
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="dropdown-item"
                      to="/division2"
                      onClick={() => handleCloseDropdown()}
                    >
                      Division 2
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="dropdown-item"
                      to="/division3"
                      onClick={() => handleCloseDropdown()}
                    >
                      Division 3
                    </NavLink>
                  </li>
                </ul> */}
              </li>

              <li  className={`nav-item dropdown ${activeLink === "specialties" ? "nav-item-active" : ""}`}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <NavLink
                    className="nav-link"
                    to="/specialties"
                    style={{ marginRight: "8px",
                      color: activeLink === "specialties" ? "#00aff0" : ""
                     }}
                  >
                    SPECIALTIES
                  </NavLink>
                  {/* <button
                    className="btn btn-link dropdown-toggle"
                    id="navbarDropdown"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{
                      padding: 0,
                      margin: 0,
                      fontSize: "10px",
                      border: "none",
                      backgroundColor: "transparent",
                      cursor: "pointer",
                    }}
                  >
                    <i className="fa-solid fa-chevron-down"></i>
                  </button> */}
                </div>

                {/* <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <NavLink
                      className="dropdown-item"
                      to="/division1"
                      onClick={() => handleCloseDropdown()}
                    >
                      Division 1
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="dropdown-item"
                      to="/division2"
                      onClick={() => handleCloseDropdown()}
                    >
                      Division 2
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="dropdown-item"
                      to="/division3"
                      onClick={() => handleCloseDropdown()}
                    >
                      Division 3
                    </NavLink>
                  </li>
                </ul> */}
              </li>
              {/* <CustomToolTip title="Coming Soon"> */}
              <li className={`nav-item dropdown ${activeLink === "healthScreen" ? "nav-item-active" : ""}`}>
                <NavLink
                  className="nav-link dropdown-toggle"
                  to="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    pointerEvents: "none",
                  }}
                >
                  SERVICES
                  {/* <i
                    className="fa-solid fa-chevron-down"
                    style={{
                      marginLeft: "8px",
                      fontSize: "10px",
                    }}
                  ></i> */}
                </NavLink>
              </li>
              {/* </CustomToolTip> */}
              {/* <li className="nav-item">
                <NavLink
                  to="/blog"
                  className="nav-link"
                  style={{ pointerEvents: "none" }}
                >
                  DEPARTMENT
                </NavLink>
              </li> */}
              <li className={`nav-item dropdown${activeLink === "#" ? "nav-item-active" : ""}`}>
                <NavLink
                  className="nav-link"
                  to="/health-screening"
                  style={{ display: "flex", alignItems: "center" ,
                    color: activeLink === "healthScreen" ? "#00aff0" : "",
                  }}
                >
                  HEALTH SCREENING & PACKAGES
                  {/* <i
                    className="fa-solid fa-chevron-down"
                    style={{
                      marginLeft: "8px",
                      fontSize: "10px",
                    }}
                  ></i> */}
                </NavLink>

                {/* <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <NavLink className="dropdown-item" to="/division1">
                      Investor 1
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="dropdown-item" to="/division2">
                      Investor 2
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="dropdown-item" to="/division3">
                      Investor 3
                    </NavLink>
                  </li>
                </ul> */}
              </li>
              <li className={`nav-item dropdown ${activeLink === "contact" ? "nav-item-active" : ""}`}>
                <NavLink to="/contact" className="nav-link" 
                style={{color: activeLink === "contact" ? "#00aff0" : ""}}
                >
                  CONTACT
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
        <div
          className="offcanvas offcanvas-start"
          tabIndex="-1"
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
        >
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
              <img
                // src={images.globallogo2x}
                src={headerData?.header_image}
                alt="global-hospital-logo-2x"
                className="navbar-logo"
                onClick={() => handleNavLinkClick("/")}
              />
            </h5>
            <button
              type="button"
              className="text-reset"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              <IoMdClose className="offcanvas-close-btn" />
            </button>
          </div>
          <div className="offcanvas-body nav-elements">
            <ul className="navbar-nav">
              {/* <li className="nav-item">
                <a
                  className="nav-link"
                 href="/"
                >
                  Home
                </a>
              </li> */}
              <li className={`nav-item dropdown${activeLink === "aboiut" ? "nav-item-active" : ""}`}>
                <NavLink className="nav-link" to="/aboutus"
                  style={{
                    color: activeLink === "about" ? "#00aff0" : ""
                  }}
                  onClick={() => handleNavLinkClick("/aboutus")}>
                  ABOUT
                </NavLink>

                {/* <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <NavLink className="dropdown-item" to="/division1">
                      Division 1
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="dropdown-item" to="/division2">
                      Division 2
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="dropdown-item" to="/division3">
                      Division 3
                    </NavLink>
                  </li>
                </ul> */}
              </li>

              <li className={`nav-item dropdown${activeLink === "specialties" ? "nav-item-active" : ""}`}>
                <NavLink className="nav-link dropdown-toggle" to="/specialties"
                  style={{
                    color: activeLink === "specialties" ? "#00aff0" : ""
                  }}
                  onClick={() => handleNavLinkClick("/specialties")}>
                  SPECIALTIES
                  {/* <i
                    className="fa-solid fa-chevron-down"
                    style={{
                      marginLeft: "8px",
                      fontSize: "10px",
                    }}
                  ></i> */}
                </NavLink>

                {/* <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <NavLink className="dropdown-item" to="/division1">
                      Division 1
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="dropdown-item" to="/division2">
                      Division 2
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="dropdown-item" to="/division3">
                      Division 3
                    </NavLink>
                  </li>
                </ul> */}
              </li>
              <li className={`nav-item dropdown ${activeLink === "#" ? "nav-item-active" : ""}`}>
                <NavLink
                  className="nav-link dropdown-toggle"
                  to="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    pointerEvents: "none",
                  }}
                // onClick={() => handleNavLinkClick("#")}
                >
                  SERVICES
                  {/* <i
                    className="fa-solid fa-chevron-down"
                    style={{
                      marginLeft: "8px",
                      fontSize: "10px",
                    }}
                  ></i> */}
                </NavLink>

                {/* <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <NavLink className="dropdown-item" to="/division1">
                      Services 1
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="dropdown-item" to="/division2">
                      Services 2
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="dropdown-item" to="/division3">
                      Services 3
                    </NavLink>
                  </li>
                </ul> */}
              </li>
              {/* <li className="nav-item">
                <NavLink
                  to="/blog"
                  className="nav-link"
                  style={{ pointerEvents: "none" }}
                >
                  DEPARTMENT
                </NavLink>
              </li> */}
              <li className={`nav-item dropdown ${activeLink === "healthScreen" ? "nav-item-active" : ""}`}>
                <NavLink
                  className="nav-link"
                  to="/health-screening"
                  style={{
                    display: "flex", alignItems: "center",
                    color: activeLink === "healthScreen" ? "#00aff0" : ""
                  }}
                  onClick={() => handleNavLinkClick("/health-screening")}
                >
                  HEALTH SCREENING & PACKAGES
                  {/* <i
                    className="fa-solid fa-chevron-down"
                    style={{
                      marginLeft: "8px",
                      fontSize: "10px",
                    }}
                  ></i> */}
                </NavLink>

                {/* <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <NavLink className="dropdown-item" to="/division1">
                      Investor 1
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="dropdown-item" to="/division2">
                      Investor 2
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="dropdown-item" to="/division3">
                      Investor 3
                    </NavLink>
                  </li>
                </ul> */}
              </li>
              <li className={`nav-item ${activeLink === "contact" ? "nav-item-active" : ""}`}>
                <NavLink to="/contact" className="nav-link"
                style={{color: activeLink === "contact" ? "#00aff0" : ""}}
                onClick={() => handleNavLinkClick("/contact")}>
                  CONTACT
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
